import { NgModule, Injectable } from "@angular/core";
import { Routes, RouterModule, CanActivate, Router } from "@angular/router";

import { AdminComponent } from "./admin/admin.component";
import { AppComponent } from "./app.component";
import { EditorComponent } from "./editor/editor.component";
import { SuperAdminComponent } from "./superadmin/superadmin.component";
import { HomeComponent } from "./home/home.component";
import { LogComponent } from "./log/log.component";
import { LoginComponent } from "./login/login.component";
import { BackgroundworkerComponent } from "./backgroundworker/backgroundworker.component";
import { AccountComponent } from "./account/account.component";
import { AdminlayoutsComponent } from "./adminlayouts/adminlayouts.component";
import { AdminbrandingComponent } from "./adminbranding/adminbranding.component";
import { AdminassetsComponent } from "./adminassets/adminassets.component";
import { AdminlayoutsdbComponent } from "./adminlayoutsdb/adminlayoutsdb.component";
import { WlusersComponent } from "./superadmin/wlusers/wlusers.component";
import { InvoicesComponent } from "./superadmin/invoices/invoices.component";
import { LogComponentB } from "./superadmin/log/log.component";
import { PaypalComponent } from "./superadmin/paypal/paypal.component";
import { AngularFireAuth } from "@angular/fire/auth";
import { Observable } from "rxjs";
import { AdmincheckoutComponent } from "./admincheckout/admincheckout.component";
@Injectable()
export class RedirectGuard implements CanActivate {
  constructor(private authService: AngularFireAuth, private router: Router) {}
  canActivate(): Observable<boolean> {
    return this.authService.authState
      .take(1)
      .map(authState => !!authState)
      .do(authenticated => {
        console.log("rediretguard ", authenticated);

        if (!authenticated) {
          this.router.navigate(["/login"]);
        }
      });
  }
}

const routes: Routes = [
  // {
  //   path: '',
  //   children: [],
  // },
  { path: "", component: EditorComponent },
  { path: "editor", component: EditorComponent },
  { path: "editor/:id", component: EditorComponent },
  { path: "admin", component: AdminComponent },
  { path: "login", component: LoginComponent },

  { path: "log", component: LogComponent },
  { path: "tasks", component: BackgroundworkerComponent },

  { path: ":company/account", component: AccountComponent },
  { path: ":company/editor", component: EditorComponent },
  { path: ":company/editor/:id", component: EditorComponent },
  {
    path: ":company/admin",
    component: AdminComponent,
    children: [
      { path: "", component: LoginComponent },
      { path: "account", component: AccountComponent, canActivate: [RedirectGuard] },

      { path: "layouts", component: AdminlayoutsComponent, canActivate: [RedirectGuard] },
      { path: "layoutsdatabase", component: AdminlayoutsdbComponent, canActivate: [RedirectGuard] },
      { path: "branding", component: AdminbrandingComponent, canActivate: [RedirectGuard] },
      { path: "order", component: AdmincheckoutComponent, canActivate: [RedirectGuard] },
      { path: "assets", component: AdminassetsComponent, canActivate: [RedirectGuard] }
    ]
  },
  {
    path: "superadmin",
    component: SuperAdminComponent,
    children: [
      { path: "wlusers", component: WlusersComponent, canActivate: [RedirectGuard] },
      { path: "", component: LoginComponent, canActivate: [RedirectGuard] },

      { path: "invoices", component: InvoicesComponent, canActivate: [RedirectGuard] },
      { path: "paypal", component: PaypalComponent, canActivate: [RedirectGuard] },
      { path: "log", component: LogComponent, canActivate: [RedirectGuard] },
      { path: "logb", component: LogComponentB, canActivate: [RedirectGuard] }
    ]
  }

  // { path: '**', redirectTo: '/account', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
