export class Label {
  public x: number;
  public y: number;
  public scaleY: number;
  public scaleX: number;
  public rotation: number;

  public width: number;
  public height: number;

  public fontfamily: string;
public  style:string;

  public fontsize: number;
  public content: string;

  public color:string;
  public strokecolor:string;
  public strokewidth:number;
}
