import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: "app-adminbranding",
  templateUrl: "./adminbranding.component.html",
  styleUrls: ["./adminbranding.component.css"]
})
export class AdminbrandingComponent implements OnInit {
  COMPANYNAME: string;
  company: any;
  constructor(public afAuth: AngularFireAuth, private activatedRoute: ActivatedRoute, public afs: AngularFirestore) {
    // this.activatedRoute.queryParams.subscribe((params: Params) => {
    //   if (params["flashbooth"]) {
    //     console.log("flashbooth ", params["flashbooth"]), (this.flashboothbrowser = true);
    //   }
    // });
  }

  ngOnInit() {
    this.activatedRoute.parent.params.subscribe(params => {
      this.COMPANYNAME = params["company"];
      console.log("thiscompanyname" + this.COMPANYNAME);
      // WL-Domains Hack
      if (
        !this.COMPANYNAME &&
        //  window.location.host.indexOf("localhost") > -1 ||
        (window.location.host.indexOf("flash-u") > -1 || window.location.host.indexOf("c172a") > -1)
      ) {
        this.COMPANYNAME = "L4f9n7g85s9elzhqfBLT";
      }

      // tslint:disable-next-line:no-unused-expression
      <any>this.afs
        .doc("wlcompanies/" + this.COMPANYNAME)
        .valueChanges()
        .subscribe(x => {
          if (x) {
            this.company = x;
            console.log("thiscomapny", this.company);
            this.company.id = this.COMPANYNAME;
          }
        });
    });
  }

  public saveSettings() {
    // tslint:disable-next-line:no-unused-expression
    <any>this.afs.doc("wlcompanies/" + this.COMPANYNAME).update(this.company);
  }

  logoUploaded($event) {
    this.company.logoUrl = $event.url;
    this.saveSettings();
  }

  uploadFailed($event) {
    alert("Upload fehlgeschlagen! Bitte versuchen Sie es erneut!");
  }
}
