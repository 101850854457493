import { Component, OnInit, ViewChild, Injectable, Inject } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";

import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { DataSource } from "@angular/cdk/collections";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { map } from "rxjs/operators";
import { Observable, of as observableOf, merge } from "rxjs";
import { LoggerService } from "../../logger.service";
// import { LogdetailComponent } from "../../_dialogs/logdetail/logdetail.component";
// import { LoggerService } from "src/app/logger.service";

@Component({
  selector: "app-log",
  templateUrl: "./log.component.html",
  styleUrls: ["./log.component.css"]
})
export class LogComponentB implements OnInit {
  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;

  filter: string;
  //  dataSource: InfotableDataSource;
  dataSource: MatTableDataSource<LogtableItem>;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ["datetime", "message", "source", "level", "meta", "functions"];

  public options: any;

  entries: any;
  constructor(public afs: AngularFirestore, ls: LoggerService, public dialog: MatDialog) {
    this.dataSource = new InfotableDataSource();
    this.dataSource.data = [
      { datetime: null, message: '<img src="/assets/ajax-loader.gif" />', source: "", level: "", meta: null },
      { datetime: null, message: '<img src="/assets/ajax-loader.gif" />', source: "", level: "", meta: null },
      { datetime: null, message: '<img src="/assets/ajax-loader.gif" />', source: "", level: "", meta: null },
      { datetime: null, message: '<img src="/assets/ajax-loader.gif" />', source: "", level: "", meta: null },
      { datetime: null, message: '<img src="/assets/ajax-loader.gif" />', source: "", level: "", meta: null },
      { datetime: null, message: '<img src="/assets/ajax-loader.gif" />', source: "", level: "", meta: null },
      { datetime: null, message: '<img src="/assets/ajax-loader.gif" />', source: "", level: "", meta: null },
      { datetime: null, message: '<img src="/assets/ajax-loader.gif" />', source: "", level: "", meta: null },
      { datetime: null, message: '<img src="/assets/ajax-loader.gif" />', source: "", level: "", meta: null },
      { datetime: null, message: '<img src="/assets/ajax-loader.gif" />', source: "", level: "", meta: null }
    ];
    this.options = "option";
  }

  openDialog() {
    // const dialogRef = this.dialog.open(LogdetailComponent, {
    //   data: { name: "austin" }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });
  }
  openEditDialog(logitem) {
    if (!logitem) {
      logitem = {};
    }

    // const dialogRef = this.dialog.open(LogdetailComponent, {
    //   data: logitem
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });
  }
  ngOnInit() {
    //  this.dataSource = new InfotableDataSource(this.afs, this.paginator, this.sort);

    this.afs
      .collection("log", ref => ref.limit(250))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          });
        })
      )
      .subscribe(x => {
        this.dataSource.data = <any>x;
        // this.dataSource.filterPredicate = (data: LogtableItem, filter: string) => {
        //   filter = filter.trim(); // Remove whitespace
        //   filter = filter.toLowerCase();
        //   return data.message.includes(filter);
        // };

        this.dataSource.filter = this.filter;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {}
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  deleteLog(id) {
    console.log("delete " + id);
    this.afs.doc("/log/" + id).delete();
  }

  newlogitem() {
    this.afs.collection("log").add({
      datetime: new Date().getTime(),
      message: "test",
      source: "superadmin",
      level: "debug",
      meta: null
    });
  }
}

// TODO: Replace this with your own data model type
export interface LogtableItem {
  datetime: any;
  message: string;
  source: string;
  level: string;
  meta: any;
}

export class InfotableDataSource extends MatTableDataSource<LogtableItem> {
  data: LogtableItem[];

  constructor() {
    super();
  }

  disconnect() {}
}

function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
