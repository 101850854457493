// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDQar-6dTbbIBREWdt9T7jmwIUVDOIL-Ac",
    authDomain: "layoutgenerator-c172a.firebaseapp.com",
    databaseURL: "https://layoutgenerator-c172a.firebaseio.com",
    projectId: "layoutgenerator-c172a",
    storageBucket: "layoutgenerator-c172a.appspot.com",
    messagingSenderId: "919374471579",
    appId: "1:919374471579:web:52db52eb7d99936b",
    measurementId: "G-DHBNNQ344J"
  }
};
