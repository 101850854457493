import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { LoggerService } from "../logger.service";
import { Http } from "@angular/http";
import { AngularFireAuth } from "@angular/fire/auth";
import { ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: "app-admincheckout",
  templateUrl: "./admincheckout.component.html",
  styleUrls: ["./admincheckout.component.css"]
})
export class AdmincheckoutComponent implements OnInit {
  loading = false;
  COMPANYNAME: string;
  company: any;
  constructor(
    public afs: AngularFirestore,
    ls: LoggerService,
    public http: Http,
    public auth: AngularFireAuth,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.parent.params.subscribe(params => {
      this.COMPANYNAME = params["company"];
      console.log("thiscompanyname" + this.COMPANYNAME);
      // WL-Domains Hack
      if (
        !this.COMPANYNAME &&
        //  window.location.host.indexOf("localhost") > -1 ||
        (window.location.host.indexOf("flash-u") > -1 || window.location.host.indexOf("c172a") > -1)
      ) {
        this.COMPANYNAME = "L4f9n7g85s9elzhqfBLT";
      }

      // tslint:disable-next-line:no-unused-expression
      <any>this.afs
        .doc("wlcompanies/" + this.COMPANYNAME)
        .valueChanges()
        .subscribe(x => {
          if (x) {
            this.company = x;
            console.log("thiscomapny", this.company);
            this.company.id = this.COMPANYNAME;
          }
        });
    });

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params["flashbooth"]) {
        console.log("flashbooth ", params["flashbooth"]);
      }
    });
  }

  public order() {
    this.loading = true;
    var description = encodeURI("Print Layout Editor Subscription - Monthly 69 USD, Period: 12 Months");

    console.log("createSubscription()");
    this.http
      .get(
        "https://us-central1-layoutgenerator-c172a.cloudfunctions.net/createsubscription?billingplanid=" +
          "P-4LX47377ED2037541PTVIQ3I" +
          "&description=" +
          description +
          "&uid=" +
          this.auth.auth.currentUser.uid +
          "&wlcompanyid=" +
          this.company.id
      )
      .subscribe(data => {
        //   this.http.get("https://us-central1-la<syoutgenerator-c172a.cloudfunctions.net/pay").subscribe(data => {

        var _body = JSON.parse((<any>data)._body);
        //     "{"approval_url":"https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=EC-3J3417293G5688715"}"
        console.log("createSubscription result ", _body);
        this.afs.collection("log").add({
          datetime: new Date().getTime(),
          message: "new user checkout " + this.auth.auth.currentUser.uid + " - " + description,
          source: "landing page",
          level: "debug",
          meta: null
        });
        window.location.href = _body.approval_url;
      });
  }
}
