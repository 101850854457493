import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Injectable } from "@angular/core";
import { LOCALE_ID } from "@angular/core";
import { AppComponent } from "./app.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { DndModule } from "ng2-dnd";
import { Directive } from "@angular/core";

import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import {
  AngularFireAnalyticsModule,
  UserTrackingService,
  ScreenTrackingService
} from "@angular/fire/analytics";

import { environment } from "../environments/environment";
import { AdminComponent } from "./admin/admin.component";
import { ColorPickerModule } from "ngx-color-picker";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { FontPickerModule } from "ngx-font-picker";
import { FONT_PICKER_CONFIG } from "ngx-font-picker";
import { FontPickerConfigInterface } from "ngx-font-picker";
import {
  PerfectScrollbarModule,
  PerfectScrollbarConfigInterface
} from "ngx-perfect-scrollbar";
import { PopoverModule } from "ngx-bootstrap/popover";
// import { defineLocale } from 'ngx-bootstrap/bs-moment';
// import { de } from 'ngx-bootstrap/locale';
// defineLocale('de', de);
// import { ServiceWorkerModule } from '@angular/service-worker'
import { defineLocale } from "ngx-bootstrap/chronos";
import { deLocale } from "ngx-bootstrap/locale";
defineLocale("de", deLocale);

import {
  EditorComponent,
  LayoutDialog,
  BackgroundDialog,
  TextsDialog,
  GraphicsDialog,
  BordersDialog
} from "./editor/editor.component";
import { AppRoutingModule, RedirectGuard } from "./app-routing.module";
import { HttpModule } from "@angular/http";
import "hammerjs";
import "hammer-timejs";

import * as firebase from "firebase";
import { ThumbnailPipe } from "./thumbnail.pipe";
import { ImagePipe } from "./tobase64.pipe";
firebase.initializeApp(environment.firebase);

import { registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";
import { UploadwidgetComponent } from "./uploadwidget/uploadwidget.component";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { SuperAdminComponent } from "./superadmin/superadmin.component";
import { SafeHtmlPipe } from "./utils/safehtmlpipe";
import { HomeComponent } from "./home/home.component";
import { SvglayoutComponent } from "./svglayout/svglayout.component";

import { APP_BASE_HREF, Location } from "@angular/common";
// import { } from './';
import { CronjobComponent } from "./cronjob/cronjob.component";
import { PhotoNrPipe } from "./photonrpipe";
import { LogComponent } from "./log/log.component";
import { BackgroundworkerComponent } from "./backgroundworker/backgroundworker.component";

// import { TourMatMenuModule } from "ngx-tour-md-menu";
// import { TourService } from "ngx-tour-md-menu";

registerLocaleData(localeDe);
import {
  MatTabsModule,
  MatDatepickerModule,
  MatTableModule,
  MatSnackBarModule,
  MatToolbarModule,
  MatFormFieldModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatInputModule,
  MatIconModule,
  MatButtonModule,
  MatMenuModule,
  MatDividerModule,
  MatAutocompleteModule,
  MatCardModule,
  MatDialogModule,
  MatGridListModule,
  MatSidenavModule,
  MatSortModule,
  MatChipsModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatSliderModule
} from "@angular/material";
import { MdePopoverModule } from "@material-extended/mde";
import { FlexLayoutModule } from "@angular/flex-layout";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AccountComponent } from "./account/account.component";
import { LoginComponent } from "./login/login.component";
import { AdminlayoutsComponent } from "./adminlayouts/adminlayouts.component";
import { AdminlayoutsdbComponent } from "./adminlayoutsdb/adminlayoutsdb.component";
import { AdminbrandingComponent } from "./adminbranding/adminbranding.component";
import { AdminassetsComponent } from "./adminassets/adminassets.component";
import { CategoryPipe } from "./adminlayoutsdb/category.pipe";
import { InvoicesComponent } from "./superadmin/invoices/invoices.component";
import { CanActivate, Router } from "@angular/router";
import { AngularFireAuth, AngularFireAuthModule } from "@angular/fire/auth";
import { Observable } from "rxjs";
import "rxjs/add/operator/do";
import "rxjs/add/operator/map";
import "rxjs/add/operator/take";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { LogComponentB } from "./superadmin/log/log.component";
import { DebuglevelPipe } from "./debuglevel.pipe";
import { WlusersComponent } from "./superadmin/wlusers/wlusers.component";
import { PaypalComponent } from "./superadmin/paypal/paypal.component";
import { AdmincheckoutComponent } from "./admincheckout/admincheckout.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AngularFirePerformanceModule } from "@angular/fire/performance";

const DEFAULT_FONT_PICKER_CONFIG: FontPickerConfigInterface = {
  // Change this to your Google API key
  apiKey: "AIzaSyCHVcMj13daEtNPvwMD-WmOVOGXNz5pRqw"
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    LayoutDialog,
    BackgroundDialog,
    TextsDialog,
    GraphicsDialog,
    BordersDialog,
    AppComponent,
    InvoicesComponent,
    AdminComponent,

    SuperAdminComponent,
    EditorComponent,
    ThumbnailPipe,
    UploadwidgetComponent,
    ImagePipe,
    SafeHtmlPipe,
    PhotoNrPipe,
    HomeComponent,
    SvglayoutComponent,
    CronjobComponent,
    LogComponent,
    LogComponentB,
    BackgroundworkerComponent,
    AccountComponent,
    LoginComponent,
    AdminlayoutsComponent,
    AdminlayoutsdbComponent,
    AdminbrandingComponent,
    AdminassetsComponent,
    CategoryPipe,
    DebuglevelPipe,
    WlusersComponent,
    PaypalComponent,
    AdmincheckoutComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    //    DndModule.forRoot(),
    ColorPickerModule,
    TooltipModule.forRoot(),
    //    DropdownModule,

    ModalModule.forRoot(),
    TabsModule.forRoot(),
    FontPickerModule,
    PopoverModule.forRoot(),
    BsDatepickerModule.forRoot(),
    MatTabsModule,
    //  TourMatMenuModule.forRoot(),
    //    environment.production ? ServiceWorkerModule.register('/ngsw-worker.js') : [],

    AngularFireModule.initializeApp(environment.firebase), // , "my-app-name"), // imports firebase/app needed for everything
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    //   AngularFirestoreModule.enablePersistence()

    FlexLayoutModule,
    MatDatepickerModule,
    MatTableModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatFormFieldModule,
    //  MomentModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatCardModule,
    MatSliderModule,
    MdePopoverModule,

    //    NgxChartsModule,
    MatDialogModule,
    MatGridListModule,
    MatTabsModule,
    MatCardModule,
    MatSidenavModule,
    MatPaginatorModule,
    MatSortModule,
    MatChipsModule,
    MatCheckboxModule,
    MatExpansionModule,
    DragDropModule,
    AngularFirePerformanceModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule
  ],
  exports: [],
  providers: [
    // {
    //     provide: APP_BASE_HREF, useValue: window['_app_base'] || '/'
    // },
    RedirectGuard,
    ScreenTrackingService,
    UserTrackingService,
    // TourService,
    // {
    //   provide: LOCALE_ID,
    //   useValue: "de-DE"
    // },

    {
      provide: FONT_PICKER_CONFIG,
      useValue: DEFAULT_FONT_PICKER_CONFIG
    }
  ],
  entryComponents: [
    LayoutDialog,
    BackgroundDialog,
    TextsDialog,
    GraphicsDialog,
    BordersDialog
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
