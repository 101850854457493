import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { fadeAnimation } from "../../animations";
import { Router } from "@angular/router";

@Component({
  selector: "app-superadmin",
  templateUrl: "./superadmin.component.html",
  styleUrls: ["./superadmin.component.css"],
  animations: [fadeAnimation]
})
export class SuperAdminComponent implements OnInit {
  company: any;
  extendededitor = false;

  constructor(public afAuth: AngularFireAuth, public router: Router) {
    this.company = {};

    if (window.location.host.indexOf("localhost") > -1) {
      this.extendededitor = true;
    }
  }

  ngOnInit() {}

  loginmail(mail, pass) {
    console.log("loginuser: ", mail, pass);
    if (mail === "info@flash-u.de") {
      // zusätzlich durch DB abgesichert!
      this.afAuth.auth
        .signInWithEmailAndPassword(mail, pass)
        .then(() => {})
        .catch(error => {
          alert(error);
        });
    } else {
      alert("keine Admin Berechtigung!");
    }
  }
  logout() {
    this.afAuth.auth.signOut();

    this.router.navigate(["/login"]);
  }
}
