import { Component, TemplateRef } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  param = { value: "world" };
  constructor(translate: TranslateService) {
    translate.setDefaultLang("de");

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use("de");
  }
}
