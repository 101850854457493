import { Component, OnInit, ViewChild, ApplicationRef, AfterViewInit, ViewChildren, QueryList } from "@angular/core";
import { Http } from "@angular/http";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { Layout } from "../model/layout";
import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";
import { BsDatepickerConfig, BsLocaleService } from "ngx-bootstrap/datepicker";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { SvglayoutComponent } from "../svglayout/svglayout.component";
import { ImagePipe } from "../tobase64.pipe";
import { Utils } from "../utils/utils";
import { PopoverDirective } from "ngx-bootstrap/popover";
import * as firebase from "firebase";
import { MatSnackBar } from "@angular/material";
import { map, throwIfEmpty } from "rxjs/operators";

declare var saveAs;
declare var Jimp;

@Component({
  selector: "app-layouts",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  vm: any;
  usero: any;
  savemessage2: string;
  oldpass: string;
  newpass: string;
  newpass2: string;
  email: any;
  authState: firebase.auth.UserCredential;

  constructor(
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    private route: ActivatedRoute,
    public router: Router,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        console.log("user", user.uid, user.email);
        this.afs
          .collection("wlcompanies", ref => ref.where("uid", "==", user.uid))
          .snapshotChanges()
          .pipe(
            map(actions => {
              return actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return { id, ...data };
              });
            })
          )
          .subscribe(x => {
            console.log("wlcompany id", x);
            if (x) {
              this.router.navigate(["/" + (<any>x[0]).id + "/admin/layouts"], {
                relativeTo: this.route
              });
            }
          }); // ns7o1FT6nYMFxZ4h2NLYsBqXMoJ2
      }
    });
  }

  ///////////////////////
  loginmail(mail, pass) {
    console.log("loginuser ", mail, pass);
    this.afAuth.auth
      .signInWithEmailAndPassword(mail, pass)
      .then(x => {
        console.log("loginresult ", x);
        //  this.router.navigate(["../admin/layouts"], { relativeTo: this.route });
      })
      .catch(error => {
        alert(error);
      });
  }

  //////////////////////////////////////

  loginWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.afAuth.auth
      .signInWithPopup(provider)
      .then(credential => {
        console.log("credential ", credential);
        //   this.authState = credential.user;
        this.router.navigate(["/dashboard"]);
      })
      .catch(error => this.snackBar.open(error, "", { duration: 12000 }));
  }
  loginWithEmail(mail, pass) {
    console.log("loginuser ", mail, pass);
    this.afAuth.auth
      .signInWithEmailAndPassword(mail, pass)
      .then(() => {
        // this.items = this.afs.collection("users", ref => ref.orderBy('Datum', 'desc')).snapshotChanges().map(actions => {
        //     return actions.map(a => {
        //         const data = a.payload.doc.data();
        //         const id = a.payload.doc.id;
        //         return { id, ...data };
        //     });
        // });
        //  this.router.navigate(["/dashboard"]);
      })
      .catch(error => {
        this.snackBar.open("Falsche E-Mail Adresse oder Passwort!", "", { duration: 12000 });
      });
  }

  logout() {
    this.afAuth.auth.signOut();
    this.router.navigate(["/login"]);
  }

  // private changeCountry(val) {
  //   this.usero.country = val;
  //   console.log(val, this.usero.country);
  //   if (
  //     this.usero.country === "Austria" ||
  //     this.usero.country === "Belgium" ||
  //     this.usero.country === "Bulgaria" ||
  //     this.usero.country === "Croatia" ||
  //     this.usero.country === "Cyprus" ||
  //     this.usero.country === "Czech Republic" ||
  //     this.usero.country === "Denmark" ||
  //     this.usero.country === "Estonia" ||
  //     this.usero.country === "Finland" ||
  //     this.usero.country === "France" ||
  //     this.usero.country === "Greece" ||
  //     this.usero.country === "Hungary" ||
  //     this.usero.country === "Ireland" ||
  //     this.usero.country === "Italy" ||
  //     this.usero.country === "Latvia" ||
  //     this.usero.country === "Lithuania" ||
  //     this.usero.country === "Belgium" ||
  //     this.usero.country === "Bulgaria" ||
  //     this.usero.country === "Croatia" ||
  //     this.usero.country === "Cyprus" ||
  //     this.usero.country === "Czech Republic" ||
  //     this.usero.country === "Luxembourg" ||
  //     this.usero.country === "Malta" ||
  //     this.usero.country === "Netherlands" ||
  //     this.usero.country === "Poland" ||
  //     this.usero.country === "Portugal" ||
  //     this.usero.country === "Romania" ||
  //     this.usero.country === "Slovakia" ||
  //     this.usero.country === "Slovenia" ||
  //     this.usero.country === "Spain" ||
  //     this.usero.country === "Sweden" ||
  //     this.usero.country === "United Kingdom"
  //   )
  //     this.showtax = true;
  //   else this.showtax = false;
  // }

  public saveNewPass() {
    if (this.newpass2 !== this.newpass) {
      this.savemessage2 = "Password confirmation incorrect";

      return;
    }

    //    this.afAuth.auth.verifyPasswordResetCode;
    this.afAuth.auth
      .confirmPasswordReset("code", this.vm.newpass)
      .then(x => {})
      .catch(err => {
        this.snackBar.open(err, "", { duration: 12000 });
      });

    // ref.changePassword(
    //   {
    //     email: this.usero.email,
    //     oldPassword: this.oldpass,
    //     newPassword: this.newpass
    //   },
    //   error => {
    //     if (error) {
    //       switch (error.code) {
    //         case "INVALID_PASSWORD":
    //           this.savemessage2 = "The specified user account password is incorrect.";
    //           break;
    //         case "INVALID_USER":
    //           this.savemessage2 = "The specified user account does not exist.";
    //           break;
    //         default:
    //           this.savemessage2 = "Error changing password: " + error;
    //       }
    //     } else {
    //       this.savemessage2 = "User password changed successfully!";
    //     }
    //   }
    // );
  }

  // public addUser() {
  //   //    this.afAuth.auth.createUserWithEmailAndPassword(this.vm.regmail, this.vm.regpass).then(x => {
  //   this.afAuth.auth
  //     .createUserAndRetrieveDataWithEmailAndPassword(this.vm.regmail, this.vm.regpass)
  //     .then(x => {})
  //     .catch(err => {
  //       this.snackBar.open(err, "", { duration: 12000 });
  //     });

  //   //   (error, fbauth) => {
  //   //     if (error) {
  //   //     } else {
  //   //       ///standardwerte festlegen
  //   //       const newuser = {
  //   //         mail: this.vm.regmail
  //   //       };
  //   //       this.afs
  //   //         .collection("users")
  //   //         .doc("erew")
  //   //         .set(newuser);

  //   //       // let r = new Firebase(this.DBSERVER + "/users/" + this.fbauth.uid);
  //   //       // r.update({
  //   //       //   subscription: {
  //   //       //     diskusage: 0,
  //   //       //     cputime: 0,
  //   //       //     typ: "free"
  //   //       //   }
  //   //       //          });
  //   //     }
  //   //   }
  //   // );
  // }

  // Returns
  get currentUserObservable(): any {
    return this.afAuth.authState;
  }

  //// Social Auth ////

  githubLogin() {
    const provider = new firebase.auth.GithubAuthProvider();
    return this.socialSignIn(provider);
  }

  googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.socialSignIn(provider);
  }

  facebookLogin() {
    const provider = new firebase.auth.FacebookAuthProvider();
    return this.socialSignIn(provider);
  }

  twitterLogin() {
    const provider = new firebase.auth.TwitterAuthProvider();
    return this.socialSignIn(provider);
  }

  private socialSignIn(provider) {
    return this.afAuth.auth
      .signInWithPopup(provider)
      .then(credential => {
        //   this.authState = credential.user;
      })
      .catch(error => this.snackBar.open(error, "", { duration: 12000 }));
  }

  //// Email/Password Auth ////

  emailSignUp(email: string, password: string) {
    return this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then(authState => {
        this.authState = authState;
        // user = firebase.auth().currentUser;
        authState.user
          .sendEmailVerification()
          .then(success => {
            console.log("registered: ", authState.user);
            const u = {
              // = new User();
              uid: authState.user.uid,
              registerDate: new Date().getTime(),
              lastLogin: new Date().getTime()
            };

            // standardwerte setzen
            this.afs.collection("/wlcompanies/").add({
              uid: authState.user.uid,
              registerDate: new Date().getTime(),
              lastLogin: new Date().getTime(),
              istestaccount: true,
              name: authState.user.email,
              notiz: "registriert über " + this.router.url,
              pass: password,
              email: authState.user.email,
              layoutanzahl: 0,
              guthaben: 0,
              backgroundColor: "#916767",
              buttonActiveColor: "#706262",
              buttonBackgroundColor: "#cc7373",
              buttonForegroundColor: "#8f1f1f",
              buttonHoverColor: "#ca7e7e",
              headBackgroundColor: "#ce6969",
              headColor: "#c29e9e",
              kategorie1: "Sample Catecory",
              linkForegroundColor: "#a96d6d",
              showLayoutsDatabase: false,
              showWelcomeScreen: false,
              sidebarBackgroundColor: "#762121",
              sidebarForegroundColor: "#915151",
              standardlayoutid: "",

              standardlayouts: JSON.stringify({
                standardlayouts: {
                  querformat: [
                    [
                      {
                        kategorie: "Standard-Layout",
                        name: "Grid 2b",
                        x: 75,
                        y: 165,
                        width: 670,
                        height: 446,
                        psdvorlage: "https://files.flash-u.com/layout-vorlagen/grid2b.psd",
                        pngvorlage: "https://files.flash-u.com/layout-vorlagen/grid2b.png"
                      },
                      {
                        x: 762,
                        y: 165,
                        width: 670,
                        height: 446
                      },
                      {
                        x: 75,
                        y: 628,
                        width: 670,
                        height: 446
                      },
                      {
                        x: 762,
                        y: 628,
                        width: 670,
                        height: 446
                      }
                    ],
                    [
                      {
                        kategorie: "Standard-Layout",
                        name: "3x1",
                        x: 39,
                        y: 772,
                        width: 564,
                        height: 376,
                        psdvorlage: "https://files.flash-u.com/layout-vorlagen/3x1.psd",

                        pngvorlage: "https://files.flash-u.com/layout-vorlagen/3x1.png"
                      },
                      {
                        x: 638,
                        y: 772,
                        width: 564,
                        height: 376
                      },
                      {
                        x: 1237,
                        y: 772,
                        width: 564,
                        height: 376
                      },
                      {
                        x: 39,
                        y: 92,
                        width: 968,
                        height: 645
                      }
                    ],
                    [
                      {
                        kategorie: "Standard-Layout",
                        name: "Emoticon",
                        x: 95,
                        y: 141,
                        width: 680,
                        height: 453,
                        psdvorlage: "https://files.flash-u.com/layout-vorlagen/emoticon.psd",

                        pngvorlage: "https://files.flash-u.com/layout-vorlagen/emoticon.png"
                      },
                      {
                        x: 827,
                        y: 141,
                        width: 680,
                        height: 453
                      },
                      {
                        x: 95,
                        y: 646,
                        width: 680,
                        height: 453
                      },
                      {
                        x: 827,
                        y: 646,
                        width: 680,
                        height: 453
                      }
                    ],
                    [
                      {
                        kategorie: "Standard-Layout",
                        name: "Logo rechts",
                        x: 36,
                        y: 74,
                        width: 812,
                        height: 541,
                        psdvorlage: "https://files.flash-u.com/layout-vorlagen/logo-rechts.psd",
                        pngvorlage: "https://files.flash-u.com/layout-vorlagen/logo-rechts.png"
                      },
                      {
                        x: 858,
                        y: 74,
                        width: 812,
                        height: 541
                      },
                      {
                        x: 36,
                        y: 625,
                        width: 812,
                        height: 541
                      },
                      {
                        x: 858,
                        y: 625,
                        width: 812,
                        height: 541
                      }
                    ],
                    [
                      {
                        kategorie: "Standard-Layout",
                        name: "Logo links und rechts",
                        x: 177,
                        y: 119,
                        width: 730,
                        height: 486,
                        psdvorlage: "https://files.flash-u.com/layout-vorlagen/logo-links-rechts.psd",

                        pngvorlage: "https://files.flash-u.com/layout-vorlagen/logo-links-rechts.png"
                      },
                      {
                        x: 937,
                        y: 119,
                        width: 730,
                        height: 486
                      },
                      {
                        x: 177,
                        y: 635,
                        width: 730,
                        height: 486
                      },
                      {
                        x: 937,
                        y: 635,
                        width: 730,
                        height: 486
                      }
                    ],
                    [
                      {
                        kategorie: "Standard-Layout",
                        name: "vierer",
                        x: 60,
                        y: 37,
                        width: 850,
                        height: 566,
                        psdvorlage: "https://files.flash-u.com/layout-vorlagen/vierer.psd",

                        pngvorlage: "https://files.flash-u.com/layout-vorlagen/vierer.png"
                      },
                      {
                        x: 933,
                        y: 37,
                        width: 850,
                        height: 566
                      },
                      {
                        x: 60,
                        y: 626,
                        width: 850,
                        height: 566
                      },
                      {
                        x: 933,
                        y: 626,
                        width: 850,
                        height: 566
                      }
                    ],
                    [
                      {
                        kategorie: "Standard-Layout",
                        name: "3x1 Logo klein",
                        x: 87,
                        y: 803,
                        width: 550,
                        height: 366,
                        psdvorlage: "https://files.flash-u.com/layout-vorlagen/3x1_klein.psd",

                        pngvorlage: "https://files.flash-u.com/layout-vorlagen/3x1_klein.png"
                      },
                      {
                        x: 647,
                        y: 803,
                        width: 550,
                        height: 366
                      },
                      {
                        x: 1207,
                        y: 803,
                        width: 550,
                        height: 366
                      },
                      {
                        x: 87,
                        y: 55,
                        width: 1110,
                        height: 740
                      }
                    ],
                    [
                      {
                        kategorie: "Standard-Layout",
                        name: "Grid 2",
                        x: 118,
                        y: 118,
                        width: 746,
                        height: 497,
                        psdvorlage: "https://files.flash-u.com/layout-vorlagen/grid2.psd",

                        pngvorlage: "https://files.flash-u.com/layout-vorlagen/grid2.png"
                      },
                      {
                        x: 873,
                        y: 118,
                        width: 746,
                        height: 497
                      },
                      {
                        x: 118,
                        y: 624,
                        width: 746,
                        height: 497
                      },
                      {
                        x: 873,
                        y: 624,
                        width: 746,
                        height: 497
                      }
                    ],
                    [
                      {
                        kategorie: "Standard-Layout",

                        name: "Fancy 4 Up",
                        x: 457,
                        y: 81,
                        width: 650,
                        height: 433,
                        psdvorlage: "https://files.flash-u.com/layout-vorlagen/fancy-4-up.psd",

                        pngvorlage: "https://files.flash-u.com/layout-vorlagen/fancy-4-up.png"
                      },
                      {
                        x: 1125,
                        y: 81,
                        width: 650,
                        height: 433
                      },
                      {
                        x: 457,
                        y: 726,
                        width: 650,
                        height: 433
                      },
                      {
                        x: 1125,
                        y: 726,
                        width: 650,
                        height: 433
                      }
                    ],
                    [
                      {
                        kategorie: "Standard-Layout",

                        name: "Vier rotiert",
                        x: 202,
                        y: 229,
                        width: 670,
                        height: 446,
                        psdvorlage: "https://files.flash-u.com/layout-vorlagen/vier-rotiert.psd",

                        pngvorlage: "https://files.flash-u.com/layout-vorlagen/vier-rotiert.png"
                      },
                      {
                        x: 882,
                        y: 109,
                        width: 670,
                        height: 446
                      },
                      {
                        x: 292,
                        y: 685,
                        width: 670,
                        height: 446
                      },
                      {
                        x: 972,
                        y: 565,
                        width: 670,
                        height: 446
                      }
                    ],
                    [
                      {
                        kategorie: "Standard-Layout",

                        name: "Einzelbild",
                        x: 0,
                        y: 0,
                        width: 1860,
                        height: 1240,

                        psdvorlage: "https://files.flash-u.com/layout-vorlagen/einzelbild.psd",

                        pngvorlage: "https://files.flash-u.com/layout-vorlagen/einzelbild.png"
                      }
                    ],
                    [
                      {
                        kategorie: "Standard-Layout",

                        name: "Einzelbild mit Logo",
                        x: -207,
                        y: 0,
                        width: 1860,
                        height: 1240,

                        psdvorlage: "https://files.flash-u.com/layout-vorlagen/einzelbild-mit-logo.psd",

                        pngvorlage: "https://files.flash-u.com/layout-vorlagen/einzelbild-mit-logo.png"
                      }
                    ],
                    [
                      {
                        kategorie: "Standard-Layout",

                        name: "Einzelbild mit Rand",
                        x: 122,
                        y: 87,
                        width: 1600,
                        height: 1066,

                        psdvorlage: "https://files.flash-u.com/layout-vorlagen/einzelbild-mit-rand.psd",

                        pngvorlage: "https://files.flash-u.com/layout-vorlagen/einzelbild-mit-rand.png"
                      }
                    ]
                  ],
                  hochformat: [
                    [
                      {
                        kategorie: "Spiegel-Layout",

                        name: "Spiegel",
                        x: 114,
                        y: 114,
                        width: 1012,
                        height: 1372,

                        psdvorlage: "https://files.flash-u.com/layout-vorlagen/spiegel.psd",

                        pngvorlage: "https://files.flash-u.com/layout-vorlagen/spiegel.png"
                      }
                    ],
                    [
                      {
                        kategorie: "Spiegel-Layout",
                        name: "Spiegel-4er",
                        x: 59,
                        y: 54,
                        width: 532,
                        height: 798,
                        psdvorlage: "https://files.flash-u.com/layout-vorlagen/4er-Spiegel.psd",

                        pngvorlage: "https://files.flash-u.com/layout-vorlagen/4er-Spiegel.png"
                      },
                      {
                        x: 649,
                        y: 54,
                        width: 532,
                        height: 798
                      },
                      {
                        x: 59,
                        y: 990,
                        width: 532,
                        height: 798
                      },
                      {
                        x: 649,
                        y: 990,
                        width: 532,
                        height: 798
                      }
                    ],
                    [
                      {
                        kategorie: "Streifen-Layout",

                        name: "Double strip",
                        x: 52,
                        y: 52,
                        width: 535,
                        height: 356,

                        psdvorlage: "https://files.flash-u.com/layout-vorlagen/double-strip.psd",

                        pngvorlage: "https://files.flash-u.com/layout-vorlagen/double-strip.png"
                      },
                      {
                        x: 52,
                        y: 460,
                        width: 535,
                        height: 356
                      },
                      {
                        x: 52,
                        y: 868,
                        width: 535,
                        height: 356
                      },
                      {
                        x: 52,
                        y: 1276,
                        width: 535,
                        height: 356
                      },
                      {
                        x: 653,
                        y: 52,
                        width: 535,
                        height: 356
                      },
                      {
                        x: 653,
                        y: 460,
                        width: 535,
                        height: 356
                      },
                      {
                        x: 653,
                        y: 868,
                        width: 535,
                        height: 356
                      },
                      {
                        x: 653,
                        y: 1276,
                        width: 535,
                        height: 356
                      }
                    ],
                    [
                      {
                        kategorie: "Streifen-Layout",

                        name: "Double strip 3er",
                        x: 52,
                        y: 52,
                        width: 535,
                        height: 356,

                        psdvorlage: "https://files.flash-u.com/layout-vorlagen/double-strip-3er.psd",

                        pngvorlage: "https://files.flash-u.com/layout-vorlagen/double-strip-3er.png"
                      },
                      {
                        x: 52,
                        y: 460,
                        width: 535,
                        height: 356
                      },
                      {
                        x: 52,
                        y: 868,
                        width: 535,
                        height: 356
                      },
                      {
                        x: 653,
                        y: 52,
                        width: 535,
                        height: 356
                      },
                      {
                        x: 653,
                        y: 460,
                        width: 535,
                        height: 356
                      },
                      {
                        x: 653,
                        y: 868,
                        width: 535,
                        height: 356
                      }
                    ],

                    [
                      {
                        kategorie: "Standard-Layout",

                        name: "2 Bilder",
                        x: 50,
                        y: 50,
                        width: 1140,
                        height: 760,

                        psdvorlage: "https://files.flash-u.com/layout-vorlagen/2-bilder.psd",

                        pngvorlage: "https://files.flash-u.com/layout-vorlagen/2-bilder.png"
                      },
                      {
                        x: 50,
                        y: 860,
                        width: 1140,
                        height: 760
                      }
                    ]
                  ]
                }
              })
            });

            console.log("user: ", u);
            this.afs.collection("log").add({
              datetime: new Date().getTime(),
              message: "new user registration " + authState.user.email,
              source: "landing page",
              level: "debug",
              meta: null
            });

            this.snackBar.open("please verify your email", "", { duration: 12000 });
            //   this.router.navigateByUrl("/dashboard");
          })
          .catch(err => {
            this.snackBar.open(err, "", { duration: 12000 });
          });
      })
      .catch(error => this.snackBar.open(error, "", { duration: 12000 }));
  }

  // Sends email allowing user to reset password
  resetPassword(email: string) {
    const auth = firebase.auth();

    return auth
      .sendPasswordResetEmail(email)
      .then(() => this.snackBar.open("email sent", "", { duration: 12000 }))
      .catch(error => this.snackBar.open(error, "", { duration: 12000 }));
  }
}
