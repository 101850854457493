import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
  selector: "app-log",
  templateUrl: "./log.component.html",
  styleUrls: ["./log.component.css"]
})
export class LogComponent implements OnInit {
  public logentries;

  constructor(private afs: AngularFirestore) {
    this.logentries = afs.collection("log1", ref => ref.orderBy("datetime", "desc").limit(500)).valueChanges();
  }

  ngOnInit() {}
}
