import {
  Component,
  ViewEncapsulation,
  TemplateRef,
  ViewChild,
  NgZone,
  ApplicationRef,
  AfterViewInit,
  OnInit,
  ElementRef,
  Renderer,
  Renderer2,
  Inject,
  OnDestroy
} from "@angular/core";
// trigger, state, style, transition, animate,

import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { Layout } from "../model/layout";
import { Photoframe } from "../model/photoframe";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { Http } from "@angular/http";
// import { Observable } from "rxjs/Observable";
import { Label } from "../model/label";
import { GrafikElement } from "../model/grafikelement";
import { Font } from "ngx-font-picker";
import { Upload } from "../model/upload";
import { BsDatepickerConfig, DatepickerConfig, BsLocaleService } from "ngx-bootstrap/datepicker";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ImagePipe } from "../tobase64.pipe";
import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";
import { DatePipe } from "@angular/common";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { SvglayoutComponent } from "../svglayout/svglayout.component";
import { Utils } from "../utils/utils";
// import { TourService, IStepOption } from "ngx-tour-md-menu";
import { ParseTreeResult } from "@angular/compiler";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { TabsetComponent, TabDirective } from "ngx-bootstrap/tabs";

declare var saveSvgAsPng;
declare var saveAs;
declare var Jimp;
declare var canvg;
declare var WebFont;

import { AngularFirePerformance } from "@angular/fire/performance";
import { ComponentFactoryResolver } from "@angular/core/src/render3";
import { ContentObserver } from "@angular/cdk/observers";
// const perf = firebase.performance;

@Component({
  selector: "app-editor",
  templateUrl: "./editor.component.html",
  styleUrls: ["./editor.component.css"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("movePanel", [
      state(
        "inactive",
        style({
          width: "0px"
        })
      ),
      state(
        "active",
        style({
          width: "650px"
        })
      ),
      transition("inactive => active", animate("100ms ease-in")),
      transition("active => inactive", animate("100ms ease-out"))
    ])
  ]
})
export class EditorComponent implements AfterViewInit, OnInit, OnDestroy {
  title = "app";
  @ViewChild("svglayout")
  svglayout: SvglayoutComponent;
  public COMPANYNAME = "flashu";
  public UPLOADPATH = "flashu/useruploads/";

  public layout: Layout;
  public allLayouts: any;
  simpleDrop: any = null;
  model: any;
  public lastX = 0;
  public lastY = 0;
  public posX = 100;
  public posY = 100;
  public scaleX = 1;
  public scaleY = 1;
  public rotate = 0;
  public format = "querformat";
  public leftDown = false;

  selectedTabIndex = 0;
  public fotobackgroundcolor = "#22f3cb";

  public color = "#22f3cb";

  public showDatabase = true;
  public loading = false;
  public expvisible = false;
  public backgroundImage = "assets/Hintergruende/querformat/hgq_striche9.png";
  modalRef: BsModalRef;
  public actiontype: string;
  public zoom = 0.4;
  public wartenmessage: string;
  private _presetFonts = [
    "Oswald",
    "Ubuntu",
    "Pacifico",
    "Francois One",
    "Roboto Slab",
    "Shrikhand",
    "Fugaz One",
    "Michroma"
  ];

  public font: Font = new Font({
    family: "Oswald",
    size: "14px",
    style: "regular",
    styles: ["regular"]
  });
  public sizeSelect = true;
  public styleSelect = true;
  public sessionID: string;
  public presetFonts = this._presetFonts;

  // public grafikelemente = [];
  // public labels = [];

  public selectedObject: any;

  public grafiken: any = {};
  public standardLayouts;
  layoutNotAvailable: boolean;
  activebtn: any;
  x = 0;
  y = 0;
  startX = 0;
  startY = 0;
  state = "inactive";
  customerPic = "";

  selectedLabel: Label;
  datumVeranstaltungbsDatepicker: any;
  transferData: Object = { id: 1, msg: "Hello" };
  receivedData: Array<any> = [];
  isModernBrowser = true;
  minDate = new Date();
  maxDate = new Date().setMonth(this.minDate.getMonth() + 12);
  // Datepicker
  bsValue: Date; // =  new Date();
  bsRangeValue: any = [new Date(2017, 7, 4), new Date(2017, 7, 20)];
  bsConfig: Partial<BsDatepickerConfig>;
  hover: boolean;
  flashulayoutchoosed = false;
  fullyloaded = false;

  graphicCategoriesArray: any;
  graphicCategories: any;

  graphicsid: any;
  graphicsarray: any;
  step1 = false;
  step2 = false;
  step3 = false;
  step4 = false;
  step5 = false;

  screenTrace: any; // AngularFirePerformance.trace;

  @ViewChild("template")
  template;
  @ViewChild("templateCustomerImage")
  templateCustomerImage;
  @ViewChild("template2")
  template2;
  //  @ViewChild("tabset1") tab: TabDirective;
  @ViewChild("tabSet") tabset: TabsetComponent;

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    public modalService: BsModalService,
    public localeService: BsLocaleService,
    private activatedRoute: ActivatedRoute,
    public http: Http,
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    public zone: NgZone,
    public dialog: MatDialog,
    public perf: AngularFirePerformance
  ) {
    this.loading = true;
    this.model = {};
    this.bsConfig = Object.assign({}, { locale: "de" }, { containerClass: "theme-red" });
    this.localeService.use("de");

    // grafiken und Standardlayouts aus json laden
    http.get("assets/grafiken.json").subscribe(x => {
      this.grafiken = x.json().grafiken;
    });

    this.sessionID = Math.random()
      .toString(36)
      .substring(2, 10);

    // standardlayout
    this.layout = new Layout();
    this.layout.width = 1844;
    this.layout.height = 1240;
    this.layout.rahmenBreite = 0;
    this.layout.rahmenRadius = 0;
    this.layout.photoBorderWidth = 2;
    this.layout.photoBorderRadius = "2";

    this.layout.showCut = true;
    var label1: Label = new Label();
    label1.fontfamily = "Oswald";
    label1.fontsize = 120;
    label1.strokecolor = "#000000";
    label1.strokewidth = 2;
    label1.color = "#FFFFFF";
    this.selectedLabel = label1;
    this.counter = 1;
  }

  public company: any;
  public isFlashu = false;
  public isSEO = false;
  inittrace: any;

  public ngOnInit() {
    // this.inittrace = this.perf.performance.trace("editorinit");
    // this.inittrace.incrementMetric("loadcount");
    // this.inittrace.start();

    // this.screenTrace = this.perf.performance.trace("editor");
    // this.screenTrace.start();

    this.activatedRoute.params.subscribe((params: Params) => {
      console.log("windowlocation ", window.location.host);
      this.checkBrowsers();
      if (this.isModernBrowser === false) {
        return;
      }

      this.COMPANYNAME = params["company"];
      // WL-Domains Hack
      if (
        !this.COMPANYNAME &&
        //        window.location.host.indexOf("localhost") > -1 ||
        (window.location.host.indexOf("flash-u") > -1 || window.location.host.indexOf("c172a") > -1)
      ) {
        this.COMPANYNAME = "L4f9n7g85s9elzhqfBLT";
      }
      console.log("this.COMPANYNAME", this.COMPANYNAME);
      if (this.COMPANYNAME === "L4f9n7g85s9elzhqfBLT") {
        this.isFlashu = true; // hier der auermühle fork
      }
      this.afs.collection("log1").add({
        datetime: new Date().getTime(),
        companyid: this.COMPANYNAME,
        session: this.sessionID,
        message: "init",
        typ: "debug",
        browser: Utils.get_browser()
      });

      // WL-Einstellungen laden
      var wl = <any>this.afs
        .doc("wlcompanies/" + this.COMPANYNAME)
        .valueChanges()
        .subscribe(x => {
          this.company = x;
          // theme setzen
          this.setTheme();
          // Standardlayouts laden und erstes anzeigen
          this.standardLayouts = JSON.parse(this.company.standardlayouts).standardlayouts;

          // willkommensbildschirm
          if (this.company.showWelcomeScreen && !params["id"] && this.isModernBrowser !== false) {
            this.modalRef = this.modalService.show(this.template);
          }

          // layouts laden
          // tslint:disable-next-line:no-shadowed-variable
          var temp = <any>this.afs
            .doc("companies/" + this.COMPANYNAME + "/userlayouts/" + params["id"])
            .valueChanges()
            .subscribe(x => {
              console.log("layout: ", x);

              // Layout über Paramter  laden
              if (params["id"]) {
                // tslint:disable-next-line:no-shadowed-variable
                var temp = <any>this.afs
                  .doc("companies/" + this.COMPANYNAME + "/userlayouts/" + params["id"])
                  .valueChanges()
                  .subscribe(x3 => {
                    (<any>x).datumVeranstaltung = null; // datepicker bug hack
                    this.layout = <any>x3;
                    this.loadLayout(this.layout);
                    this.fullyloaded = true;
                    this.loading = false;
                    console.log("layout: ", x3);
                  });
              } else {
                // standardlayout laden, auermühle hack
                var standardlayoutid = "ltof7J8phfiqEwm3WRoT"; //this.company.standardlayoutid;
                // if (!standardlayoutid) {
                //   standardlayoutid = "Lrpcdm4maugrK6mrhmeK"; // achtung, funzt nur bei flashu
                // }

                console.log("standardlayoutid " + standardlayoutid);
                if (standardlayoutid) {
                  this.afs
                    .doc("companies/" + this.COMPANYNAME + "/userlayouts/" + standardlayoutid)
                    .valueChanges()
                    .subscribe(x4 => {
                      if (x4) {
                        (<any>x4).datumVeranstaltung = null; // datepicker bug hack
                        this.layout = <any>x4;
                        this.loadLayout(this.layout, true);
                        this.layout.auftragsnummerName = ""; // TODO: noetig?
                        this.layout.showBranding = false;
                        if (this.flashulayoutchoosed) {
                          this.changeLayout("hochformat");
                          this.setStandardLayout(this.standardLayouts.hochformat[0]);
                        }
                      }

                      // http://layout.flash-u.com/?orderId=PAU-18-D29L0KB&eventDate=2018-07-28&key=agxzfm1haWxmb29nYWVyKwsSDE9yZ2FuaXphdGlvbiILZmxhc2gtdS5jb20MCxIEQ2FzZRjhp5_GAww&wp
                      this.activatedRoute.queryParams.subscribe(p => {
                        console.log(p);
                        if (p.orderId) {
                          this.layout.auftragsnummerName = p.orderId;
                        }
                        if (p.eventDate) {
                          this.datumVeranstaltungbsDatepicker = new Date(Date.parse(p.eventDate));
                        }
                        // hack für flashu: spiegel auch bei parameterangaben wählbar machen
                        if (p.eventDate && p.orderId) {
                        }
                      });
                      this.fullyloaded = true;
                      this.loading = false;
                      console.log("layout: ", x4);
                      // anderer willkommensschirm für flashu!
                      if (this.COMPANYNAME === "L4f9n7g85s9elzhqfBLT") {
                        this.modalRef = this.modalService.show(this.template2);
                      }
                    });
                } else {
                  // kein standardlayout
                  this.fullyloaded = true;
                  this.loading = false;
                  // anderer willkommensschirm für flashu!
                  if (this.COMPANYNAME === "L4f9n7g85s9elzhqfBLT") {
                    this.modalRef = this.modalService.show(this.template2);
                  }
                }
              }

              this.changeLayout(null);

              // Layout-Datenbank
              this.changeCategory("kategorie1");

              // tslint:disable-next-line:no-shadowed-variable
              this.afs
                .collection<any>("companies/" + this.COMPANYNAME + "/userlayoutnames")
                .valueChanges()
                .subscribe(x2 => {
                  this.layoutnames = x2;
                  //    this.inittrace.putAttribute("loaded", "true");
                  //    this.screenTrace.stop();
                });
            });
        });
    });

    //  this.inittrace.stop();
  }

  // this.tourService.initialize([
  //   {
  //     anchorId: "modulea",
  //     content: "Some content<br> <strong>test flksdj fls</strong>",
  //     title: "First",
  //     enableBackdrop: true
  //   },
  //   {
  //     anchorId: "moduleb",
  //     content: "Other content",
  //     title: "Second",
  //     enableBackdrop: true
  //   }
  // ]);
  // this.tourService.stepShow$.subscribe((steps: any) => {
  //   console.log("tour configured with these steps:", steps);
  // });

  public ngOnDestroy() {
    this.screenTrace.stop();
  }
  public ngAfterViewInit() { }

  private loadgraphics() {
    this.graphicCategories = this.afs
      .collection("wlcompanies/" + this.COMPANYNAME + "/graphics")
      .snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Layout;
          const id2 = a.payload.doc.id;
          data.id2 = id2;
          const id = a.payload.doc.id;
          return { id2, id, ...data };
        });
      });
    this.graphicCategories.subscribe(x => {
      console.log("this.graphicCategoriesArray", x);
      this.graphicCategoriesArray = x;
    });
  }
  tabClick(tab) {
    if (tab && this.graphicCategoriesArray) {
      if (this.graphicCategoriesArray.length > 0) {
        this.graphicsid = this.graphicCategoriesArray[tab.index].id;
        this.afs
          .collection(
            "wlcompanies/" + this.COMPANYNAME + "/graphics/" + this.graphicCategoriesArray[tab.index].id + "/urls",
            ref => ref.orderBy("index")
          )
          .snapshotChanges()
          .map(actions => {
            return actions.map(a => {
              const data = a.payload.doc.data() as Layout;
              const id2 = a.payload.doc.id;
              data.id2 = id2;
              const id = a.payload.doc.id;
              return { id2, id, ...data };
            });
          })
          .subscribe(x => {
            this.graphicsarray = x;
          });
      }
    }
  }

  public checkBrowsers() {
    var version = this.detectIE();
    console.log("browser version: ", version);
    if (version === false) {
      // chrome/firefox
    } else if (version >= 18) {
      this.isModernBrowser = false;
    } else {
      this.isModernBrowser = false;
    }

    // For use within normal web clients
    var isiPad = navigator.userAgent.match(/iPad/i) != null;

    // For use within iPad developer UIWebView
    var ua = navigator.userAgent;
    var isiPad = /iPad/i.test(ua) || /iPhone OS 3_1_2/i.test(ua) || /iPhone OS 3_2_2/i.test(ua);
    if (isiPad) {
      this.isModernBrowser = false;
    }

    this.isModernBrowser = true;
  }

  public newLayout() {
    // standardlayout
    //    this.layout = new Layout();
    this.layout.width = 1844;
    this.layout.height = 1240;
    this.layout.rahmenBreite = 0;
    this.layout.rahmenRadius = 0;
    this.layout.backgroundcolor = "#5d7256";
    this.layout.photoBorderWidth = 0;
    this.layout.photoBorderColor = "#eeeeee";
    this.layout.photoBorderRadius = "0";
    this.layout.showCut = true;
    this.layout.showBranding = false;

    this.setStandardLayout(this.standardLayouts.querformat[0]);
    this.svglayout.isStripLayout();

    this.layout.format = "querformat";

    this.layout.labels = [];
    this.layout.grafikelemente = [];
  }

  /**
   * detect IE
   * returns version of IE or false, if browser is not Internet Explorer
   */
  private detectIE() {
    var ua = window.navigator.userAgent;
    // Test values; Uncomment to check result …
    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';
    var msie = ua.indexOf("MSIE ");
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
    }

    var trident = ua.indexOf("Trident/");
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf("rv:");
      return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }

    var edge = ua.indexOf("Edge/");
    if (edge > 0) {
      console.log('isEDGE')
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
    }

    // other browser
    return false;
  }
  public setTheme() {
    var selects = document.getElementsByClassName("btn-flashu");
    for (var i = 0, il = selects.length; i < il; i++) {
      (<any>selects[i]).style.color = this.company.buttonForegroundColor;
      (<any>selects[i]).style.backgroundColor = this.company.buttonBackgroundColor;
    }

    var selects = document.getElementsByClassName("link-flashu");
    for (var i = 0, il = selects.length; i < il; i++) {
      (<any>selects[i]).style.color = this.company.linkForegroundColor;
      //   (<any>selects[i]).style.color.hover = this.company.linkBackgroundColor;
    }

    this.renderer.setStyle(document.body, "background-color", this.company.backgroundColor);
  }

  public changeLayout(format: string) {
    if (format) {
      this.layout.format = format;
    }
    if (this.layout.format === "querformat") {
      this.layout.width = 1844;
      this.layout.height = 1240;
      this.zoom = 0.7;
      this.setStandardLayout(this.standardLayouts.querformat[0]);
    } else {
      this.layout.width = 1240;
      this.layout.height = 1844;
      this.zoom = 0.5;
      this.setStandardLayout(this.standardLayouts.hochformat[0]);
    }

    window.dispatchEvent(new Event("resize"));
  }

  onResize(event) {
    var svgwidth = event.target.innerWidth - 350;
    var svgheight = event.target.innerHeight;

    if (this.layout.format === "querformat") {
      this.layout.width = 1844;
      this.layout.height = 1240;

      var ratioX = svgwidth / this.layout.width;
      var ratioY = svgheight / this.layout.height;
      if (ratioX > ratioY) {
        ratioX = ratioY;
      }

      this.zoom = ratioX - 0.068;
    } else {
      this.layout.width = 1240;
      this.layout.height = 1844;
      var ratioX = svgwidth / this.layout.width;
      var ratioY = svgheight / this.layout.height;
      if (ratioX > ratioY) {
        ratioX = ratioY;
      }

      this.zoom = ratioX - 0.044;
    }

    console.log("resize", event.target.innerWidth);
  }

  public setStandardLayout(l) {
    console.log("standardlayout ", l);
    this.layout.photoframes = [];

    for (var i = 0; i < l.length; i++) {
      let newframe: any = {}; // new Photoframe();
      newframe.x = l[i].x;
      newframe.y = l[i].y;
      newframe.width = l[i].width;

      if (l[i].rotation) {
        newframe.rotation = l[i].rotation;
      } else {
        newframe.rotation = 0;
      }
      if (l[i].name) {
        newframe.name = l[i].name;
      }

      newframe.height = l[i].height;
      // if (l[i].name){
      //   newframe.name = l[i].name;
      // }
      // newframe.nr = i + 1;
      this.layout.photoframes.push(newframe);
    }

    this.svglayout.isStripLayout();

    this.layout.showCut = true;
  }

  public changeCategory(category: string) {
    this.allLayouts = null;
    this.allLayouts = this.afs
      .collection("companies/" + this.COMPANYNAME + "/layouts", ref => ref.where("Kategorie", "==", category))
      .valueChanges().subscribe(x => {
        console.log('allayouts', x);
      });

    // allLayouts
    // this.allLayouts = [

    // ];

  }

  public openSaveDialog(templateDialog) {
    this.layout.datumVeranstaltung = null;
    templateDialog.show();
  }

  toggleMove() {
    this.state = this.state === "inactive" ? "active" : "inactive";
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  closeModal(template: TemplateRef<any>) {
    this.modalService.hide(1);
  }

  transferDataSuccess($event: any) {
    this.receivedData.push($event);
  }

  public addText(text) {
    var newLabel: any = {}; // new Label();
    newLabel.content = text;
    newLabel.x = 100;
    newLabel.y = 200;
    newLabel.fontfamily = this.font.family;
    newLabel.fontsize = 150;
    newLabel.color = "#FFFFFF";
    newLabel.strokecolor = "#000000";
    newLabel.strokewidth = 2;
    newLabel.scaleX = 1;
    newLabel.scaleY = 1;
    newLabel.rotation = 0;
    newLabel.width = this.measureText(text, newLabel.fontsize, null).width;
    newLabel.height = this.measureText(text, newLabel.fontsize, null).height;
    this.layout.labels.push(newLabel);
    this.selectedLabel = this.layout.labels[this.layout.labels.length - 1];
    this.selectedObject = this.selectedLabel;
  }

  public editText() { }

  private measureText(pText, pFontSize, pStyle) {
    var lDiv = <any>document.createElement("div");

    document.body.appendChild(lDiv);

    if (pStyle != null) {
      lDiv.style = pStyle;
    }
    lDiv.style.fontSize = "" + pFontSize + "px";
    lDiv.style.position = "absolute";
    lDiv.style.left = -100;
    lDiv.style.top = -100;

    lDiv.innerHTML = pText;

    var lResult = {
      width: lDiv.clientWidth,
      height: lDiv.clientHeight
    };

    document.body.removeChild(lDiv);
    lDiv = null;

    return lResult;
  }
  // private getTextWidth(text, font) {
  //     // re-use canvas object for better performance
  //     var canvas = document.createElement("canvas");
  //     var context = canvas.getContext("2d");
  //     context.font = font;
  //     var metrics = context.measureText(text);
  //     return metrics.width;
  // }
  // public textChanged() {
  //     // this.selectedLabel.width = this.measureText(this.selectedLabel.content, this.selectedLabel.fontsize, null).width;
  //     // this.selectedLabel.height = this.measureText(this.selectedLabel.content, this.selectedLabel.fontsize, null).height;
  // }

  // Nutzerlayout laden
  public openLayout(frmLoad, openDialog) {
    event.preventDefault();

    this.layoutNotAvailable = false;
    this.afs
      .collection("/companies/" + this.COMPANYNAME + "/userlayouts", ref =>
        ref.where("auftragsnummerName", "==", frmLoad.controls.txtAuftragsnummerLoad.value.toLowerCase())
      )
      .valueChanges()
      .subscribe(val => {
        var layout = val;
        if (layout[0]) {
          this.loadLayout(layout[0]);

          openDialog.hide();
        } else {
          // nachricht layout ex nicht
          this.layoutNotAvailable = true;
        }
      });
  }


  public async loadLayoutID(id: string) {
    console.log('loadlayoutid');
    this.afs.doc("companies/" + this.COMPANYNAME + "/userlayouts/" + id).valueChanges().subscribe(async x4 => {
      console.log('x4', x4);
      if (x4) {
        (<any>x4).datumVeranstaltung = null; // datepicker bug hack
        this.layout = <any>x4;
        //  await this.loadLayout(this.layout);
        this.layout.auftragsnummerName = ""; // TODO: noetig?
        this.layout.showBranding = false;
        // if (this.flashulayoutchoosed) {
        //   this.changeLayout("hochformat");
        //   this.setStandardLayout(this.layout);
        // }
        window.dispatchEvent(new Event("resize"));

      }


    });
  }

  public async loadLayout(layout, showbranding = false) {
    this.layout = layout;
    this.layout.datumVeranstaltung = undefined;
    this.layout.datumVeranstaltungbsDatepicker = undefined;
    this.layout.auftragsnummerName = undefined;
    this.layout.showCut = true;

    if (this.layout.format === "querformat") {
      this.zoom = 0.4;
    } else {
      this.zoom = 0.32;
    }

    // Webfonts müssen explizit geladen werden
    for (let label of layout.labels) {
      WebFont.load({
        google: {
          families: [label.fontfamily]
        }
      });
    }
    //    this.svglayout.layout = this.layout;
    this.svglayout.isStripLayout();

    if (showbranding) {
      this.layout.showBranding = showbranding;
    }

    window.dispatchEvent(new Event("resize"));
  }

  // tslint:disable-next-line:member-ordering
  layoutnames: any;
  // tslint:disable-next-line:member-ordering
  counter: number;

  private increaseCounterToAvailable(): string {
    let nameexists = true;
    while (nameexists) {
      nameexists = false;
      console.log(this.layoutnames);
      for (let v of this.layoutnames) {
        if (v.name) {
          if (v.name.toLowerCase() === this.layout.auftragsnummerName.toLowerCase() + "-" + this.counter) {
            nameexists = true;

            this.counter = this.counter + 1;
            break;
          }
        }
      }
    }

    return this.layout.auftragsnummerName;
  }

  // tslint:disable-next-line:member-ordering
  public savesuccess: boolean;
  public saveLayout(form, modal) {
    // v1-2017-08-19_D_pau-16-i28q3oe_v1
    try {
      console.log("saveLayout ", this.layout);

      if (form.valid && this.datumVeranstaltungbsDatepicker) {
        this.loading = true;

        var dateString = new Date(this.datumVeranstaltungbsDatepicker).toString(); // .toUTCString();

        dateString = dateString
          .split(" ")
          .slice(0, 4)
          .join(" "); // Zeit entfernen

        this.layout.datumVeranstaltung = Date.parse(dateString);
        this.layout.browser = Utils.get_browser();
        this.layout.datumVeranstaltungbsDatepicker = this.layout.datumVeranstaltung;
        // alternative Koordinaten berechnen
        for (let f of this.layout.photoframes) {
          this.rotChange(f);
        }

        this.layout.auftragsnummerName = this.layout.auftragsnummerName
          .replace(/[^a-zA-Z_0-9- ]/g, "")
          .split(" ")
          .join("_");

        // ggf. Namen abändern
        this.increaseCounterToAvailable();
        var dp = new DatePipe("de");
        var d = "v1_" + dp.transform(this.layout.datumVeranstaltung, "yyyy_MM_dd");
        this.sessionID = d + "_" + this.layout.auftragsnummerName;
        this.layout.category = "";
        this.layout.auftragsnummerName = this.layout.auftragsnummerName.toLowerCase();
        this.layout.auftragsnummerName2 = this.layout.auftragsnummerName;

        this.afs.collection("log1").add({
          datetime: new Date().getTime(),
          companyid: this.COMPANYNAME,
          session: this.sessionID,
          message: "save layout, Name:" + this.layout.auftragsnummerName,
          typ: "debug",
          browser: Utils.get_browser()
        });

        this.svglayout.isStripLayout();
        modal.hide();
        // für lokale Tests
        //  this.svglayout.generateLayoutFiles(this.counter);

        // neu: serverseitig erstellen task
        var task = {
          datetime: new Date().getTime(),
          task: "regeneratelayout",
          counter: this.counter,
          state: "pending",
          layout: this.layout,
          companyname: this.COMPANYNAME
        };
        console.log("add task ", task);
        // task auf server triggern
        var tasks = this.afs
          .collection("tasks")
          .add(JSON.parse(JSON.stringify(task)))
          .then(y => console.log(y));
        console.log("weiter");
        this.savesuccess = false;
        setTimeout(() => {
          if (!this.savesuccess) {
            this.wartenmessage = "...if you have problems please contact: daniel@flash-u.com!";
            this.afs.collection("log1").add({
              datetime: new Date().getTime(),
              companyid: this.COMPANYNAME,
              session: this.sessionID,
              message:
                "save layout ZU LANGE GEDAUERT, Name:" +
                this.layout.auftragsnummerName +
                " savesuccess" +
                this.savesuccess,
              typ: "error",
              browser: Utils.get_browser()
            });
          }
        }, 30000);

        console.log("warte auf server");
        // auf ende warten
        this.afs
          .collection<any>("companies/" + this.COMPANYNAME + "/userlayouts", ref =>
            ref.where("auftragsnummerName", "==", this.layout.auftragsnummerName + "-" + this.counter)
          )
          .valueChanges()
          .subscribe(x => {
            if (x.length > 0) {
              console.log("SERVER FERTIG", x[0]);
              this.layout.generatedCustomerImage = x[0].generatedCustomerImage;
              this.layoutGenerated(null);
            }
          });

        // const timeout = Observable.throw(new Error("timed out")).delay(20000);
        // const chatWithTimeout = Observable.race(timeout, timeout);
      }
    } catch (error) {
      console.error("saveLayout FEHLER ", error);
      this.afs.collection("log1").add({
        datetime: new Date().getTime(),
        companyid: this.COMPANYNAME,
        session: this.sessionID,
        message: "save layout error, " + error,
        typ: "debug"
      });
    }
  }

  public layoutGenerated(event) {
    this.loading = false;
    this.savesuccess = true;
    this.modalRef = this.modalService.show(this.templateCustomerImage);
    this.afs.collection("log1").add({
      datetime: new Date().getTime(),
      session: this.sessionID,
      message: "save layout - layout created, close modal " + " savesuccess" + this.savesuccess,
      typ: "debug"
    });
  }

  public rotChange(f) {
    //        console.log('rotchange ', f.rotation, ' ', this.toRadians(f.rotation), '  ', Math.cos(this.toRadians(-f.rotation)));
    // f.xalt = Math.round( f.x+ f.width / 2 * (1 - Math.cos(this.toRadians(-f.rotation))));
    // f.yalt = Math.round( f.y + f.width / 2 * Math.sin(this.toRadians(-f.rotation)));

    var rotated = [];
    rotated.push(this.rotateAroundPoint(f.x + f.width / 2, f.y + f.height / 2, f.x, f.y, f.rotation));
    rotated.push(this.rotateAroundPoint(f.x + f.width / 2, f.y + f.height / 2, f.x + f.width, f.y, f.rotation));
    rotated.push(
      this.rotateAroundPoint(f.x + f.width / 2, f.y + f.height / 2, f.x + f.width, f.y + f.height, f.rotation)
    );
    rotated.push(this.rotateAroundPoint(f.x + f.width / 2, f.y + f.height / 2, f.x, f.y + f.height, f.rotation));
    var smallestX = rotated[0][0];
    var smallestY = rotated[0][1];
    rotated.forEach(element => {
      if (element[0] < smallestX) {
        smallestX = element[0];
      }
      if (element[1] < smallestY) {
        smallestY = element[1];
      }
    });

    console.log("smallesst " + smallestX, " ", smallestY);
    f.rotation = parseInt(f.rotation, 10);

    f.xalt = Math.round(smallestX);
    f.yalt = Math.round(smallestY);
    f.x = Math.round(f.x);
    f.y = Math.round(f.y);
    f.rotation = Math.round(f.rotation);

    f.widthalt = f.width;
    f.heightalt = f.height;
  }

  public toRadians(angle) {
    return angle * (Math.PI / 180);
  }
  public rotateAroundPoint(cx, cy, x, y, angle) {
    var radians = (Math.PI / 180) * angle,
      cos = Math.cos(radians),
      sin = Math.sin(radians),
      nx = cos * (x - cx) + sin * (y - cy) + cx,
      ny = cos * (y - cy) - sin * (x - cx) + cy;
    return [nx, ny];
  }

  public backgroundUploaded(event) {
    console.log("background uploaded  ", event);
    this.layout.backgroundfile = event.url;
    this.layout.backgroundtype = "uploaded";
  }
  public graphicUploaded(event) {
    console.log("graphic uploaded  ", event);
    this.addGrafikElement(event.url);
  }
  public borderUploaded(event) {
    console.log("border uploaded  ", event);
    this.layout.rahmenfile = event.url;
  }
  public uploadFailed(event) {
    window.alert("Upload fehlgeschlagen. Versuchen Sie es bitte erneut und prüfen Sie Ihre Internetverbindung.");
  }

  public removeObject(index) {
    this.layout.grafikelemente.splice(index, 1);
  }

  public removeText(index) {
    this.layout.labels.splice(index, 1);
  }

  public removePhoto(index) {
    this.layout.photoframes.splice(index, 1);
  }

  public addPhoto() {
    var frame4: any = {}; // = new Photoframe();
    frame4.x = 50;
    frame4.y = 50;
    frame4.width = 300;
    frame4.height = 200;
    frame4.rotation = 0;
    this.layout.photoframes.push(frame4);
  }
  public addGrafikElement(imgurl) {
    var img = new Image();
    img.src = imgurl;
    img.onload = event => {
      // (<any>this).width + ' ' + (<any>this).height );
      //    console.log('test ', (<any>event).path[0].width);
      console.log("addgrafikelement ", event);
      var newGrafikElement: any = {}; // new GrafikElement();
      newGrafikElement.x = 10;
      newGrafikElement.y = 100;
      newGrafikElement.scaleX = 1;
      newGrafikElement.scaleY = 1;

      newGrafikElement.width = (<any>event).target.width;
      newGrafikElement.height = (<any>event).target.height;
      if (newGrafikElement.width > this.layout.width) {
        newGrafikElement.width = this.layout.width * 0.8;
        newGrafikElement.height = ((<any>event).target.height / (<any>event).target.width) * newGrafikElement.width;
      }

      if (newGrafikElement.height > this.layout.height) {
        newGrafikElement.height = this.layout.height * 0.8;
        newGrafikElement.width = ((<any>event).target.width / (<any>event).target.height) * newGrafikElement.height;
      }

      newGrafikElement.rotation = 0;
      newGrafikElement.imgurl = imgurl;
      this.layout.grafikelemente.push(newGrafikElement);
    };
  }
  public downloadcustomerPic(customerPic: string) {
    var zip = new JSZip();

    zip.file(
      customerPic
        .split("/")
        .pop()
        .split("?")[0]
        .split("?")[0]
        .split("%2F")[3],
      <any>this.urlToPromise(customerPic),
      { binary: true }
    ); // achtung: hack! wie mit leerzeichen in namen umgehen?
    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, "flashu.zip");
    });
  }

  private urlToPromise(url: string) {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }
  //////
  openBackgroundDialog(backgroundtype): void {
    this.layout.backgroundtype = backgroundtype;

    const dialogRef = this.dialog.open(BackgroundDialog, {
      width: "750px",
      data: { layout: this.layout, grafiken: this.grafiken, COMPANYNAME: this.COMPANYNAME, layouttype: backgroundtype }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.step2 = true;
      console.log("The dialog was closed", ParseTreeResult);
      //  this.animal = result;
    });
  }

  openBordersDialog(layouttype): void {
    const dialogRef = this.dialog.open(BordersDialog, {
      width: "750px",
      data: { layout: this.layout, standardLayouts: this.standardLayouts }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.step3 = true;
      console.log("The dialog was closed", ParseTreeResult);
      //  this.animal = result;
    });
  }

  openGraphicsDialog(layouttype): void {
    const dialogRef = this.dialog.open(GraphicsDialog, {
      width: "750px",
      data: { layout: this.layout, grafiken: this.grafiken, COMPANYNAME: this.COMPANYNAME, layouttype: layouttype }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.step4 = true;
      console.log("The dialog was closed", result);

      this.selectedObject = this.layout.grafikelemente[this.layout.grafikelemente.length - 1];
    });
  }

  openTextsDialog(layouttype): void {
    const dialogRef = this.dialog.open(TextsDialog, {
      width: "750px",
      data: { layout: this.layout, selectedLabel: this.selectedLabel, font: this.font }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.step5 = true;
      console.log("The dialog was closed", result);
      //  this.animal = result;
    });
  }

  openLayoutDialog(layouttype): void {
    console.log('openlayoutdlg', this.svglayout);
    const dialogRef = this.dialog.open(LayoutDialog, {
      width: "750px",
      data: {
        layout: this.layout,
        standardLayouts: this.standardLayouts,
        layouttype: layouttype,
        company: this.company,
        svglayout: this.svglayout
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.step1 = true;
      console.log("The dialog was closed", result);
      //  this.animal = result;
    });
  }
}

export interface LayoutDialogData {
  layout: Layout;
  standardLayouts: any;
  svglayout: any;
  layouttype: string;
  company: any;
}
@Component({
  // tslint:disable-next-line:component-selector
  selector: "layout-dialog",
  templateUrl: "dlglayout.html"
})
// tslint:disable-next-line:component-class-suffix
export class LayoutDialog {
  svglayout: any;
  layout: Layout;
  standardLayouts: any;
  layouttype: string;
  company: any;
  constructor(
    public dialogRef: MatDialogRef<LayoutDialog>,
    @Inject(MAT_DIALOG_DATA) public data: LayoutDialogData,
    private renderer: Renderer2
  ) {
    console.log("layoutdialog constructor");

    this.layout = data.layout;
    this.standardLayouts = data.standardLayouts;
    this.layouttype = data.layouttype;
    this.company = data.company;
    this.svglayout = data.svglayout;
  }

  public setTheme() {
    var selects = document.getElementsByClassName("btn-flashu");
    for (var i = 0, il = selects.length; i < il; i++) {
      (<any>selects[i]).style.color = this.company.buttonForegroundColor;
      (<any>selects[i]).style.backgroundColor = this.company.buttonBackgroundColor;
    }

    var selects = document.getElementsByClassName("link-flashu");
    for (var i = 0, il = selects.length; i < il; i++) {
      (<any>selects[i]).style.color = this.company.linkForegroundColor;
      //   (<any>selects[i]).style.color.hover = this.company.linkBackgroundColor;
    }

    this.renderer.setStyle(document.body, "background-color", this.company.backgroundColor);
  }
  public changeLayout(format: string) {
    if (format) {
      this.layout.format = format;
    }
    if (this.layout.format === "querformat") {
      this.layout.width = 1844;
      this.layout.height = 1240;
      //     this.zoom = 0.7;
      this.setStandardLayout(this.standardLayouts.querformat[0]);
    } else {
      this.layout.width = 1240;
      this.layout.height = 1844;
      //   this.zoom = 0.5;
      this.setStandardLayout(this.standardLayouts.hochformat[0]);
    }

    window.dispatchEvent(new Event("resize"));
  }
  public setStandardLayout(l) {
    console.log("standardlayout ", l);
    this.layout.photoframes = [];

    for (var i = 0; i < l.length; i++) {
      let newframe: any = {}; // new Photoframe();
      newframe.x = l[i].x;
      newframe.y = l[i].y;
      newframe.width = l[i].width;

      if (l[i].rotation) {
        newframe.rotation = l[i].rotation;
      } else {
        newframe.rotation = 0;
      }
      if (l[i].name) {
        newframe.name = l[i].name;
      }

      newframe.height = l[i].height;
      // if (l[i].name){
      //   newframe.name = l[i].name;
      // }
      // newframe.nr = i + 1;
      this.layout.photoframes.push(newframe);
    }
    this.layout.layouttype = this.layouttype;

    this.svglayout.isStripLayout();



    this.layout.showCut = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: "borders-dialog",
  templateUrl: "dlgborders.html"
})
// tslint:disable-next-line:component-class-suffix
export class BordersDialog {
  layout: Layout;
  standardLayouts: any;
  constructor(public dialogRef: MatDialogRef<LayoutDialog>, @Inject(MAT_DIALOG_DATA) public data: LayoutDialogData) {
    console.log("layoutdialog constructor");

    this.layout = data.layout;
    this.standardLayouts = data.standardLayouts;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface BackgroundDialogData {
  COMPANYNAME: string;
  layout: Layout;
  grafiken: any;
  layouttype: string;
}
@Component({
  // tslint:disable-next-line:component-selector
  selector: "background-dialog",
  templateUrl: "dlgbackground.html"
})
// tslint:disable-next-line:component-class-suffix
export class BackgroundDialog {
  COMPANYNAME: string;
  layout: Layout;
  grafiken: any;

  layouttype: string;
  backgroundgraphicCategoriesArray: any;
  backgroundgraphicCategories: any;
  backgroundgraphics: any;
  constructor(
    public dialogRef: MatDialogRef<LayoutDialog>,
    @Inject(MAT_DIALOG_DATA) public data: BackgroundDialogData,
    public afs: AngularFirestore
  ) {
    console.log("layoutdialog constructor");

    this.COMPANYNAME = data.COMPANYNAME;
    this.layout = data.layout;
    this.grafiken = data.grafiken;
    this.layouttype = data.layouttype;

    this.backgroundgraphicCategories = this.afs
      .collection("wlcompanies/" + this.COMPANYNAME + "/backgroundgraphics")
      .snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data2 = a.payload.doc.data() as Layout;
          console.log("data2", data2);
          const id2 = a.payload.doc.id;
          data2.id2 = id2;
          const id = a.payload.doc.id;
          return { id2, id, ...data2 };
        });
      });
    this.backgroundgraphicCategories.subscribe(x => {
      console.log("this.backgroundgraphicCategoriesArray", x);
      this.backgroundgraphicCategoriesArray = x;
    });
  }

  backgroundtabClick(tab) {
    if (tab) {
      if (this.backgroundgraphicCategoriesArray.length > 0) {
        //  this.backgroundgraphicsid = this.backgroundgraphicCategoriesArray[tab.index].id;

        this.afs
          .collection(
            "wlcompanies/" +
            this.COMPANYNAME +
            "/backgroundgraphics/" +
            this.backgroundgraphicCategoriesArray[tab.index].id +
            "/urls",
            ref => ref.orderBy("index")
          )
          .snapshotChanges()
          .map(actions => {
            return actions.map(a => {
              const data = a.payload.doc.data() as Layout;
              const id2 = a.payload.doc.id;
              data.id2 = id2;
              const id = a.payload.doc.id;
              return { id2, id, ...data };
            });
          })
          .subscribe(x => {
            this.backgroundgraphics = x;
          });
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface GraphicsDialogData {
  COMPANYNAME: string;
  layout: Layout;
  grafiken: any;
  layouttype: string;
}
@Component({
  // tslint:disable-next-line:component-selector
  selector: "graphics-dialog",
  templateUrl: "dlggraphics.html"
})
// tslint:disable-next-line:component-class-suffix
export class GraphicsDialog {
  COMPANYNAME: string;
  layout: Layout;
  grafiken: any;

  layouttype: string;
  graphicCategoriesArray: any;
  graphicCategories: any;
  graphics: any;
  constructor(
    public dialogRef: MatDialogRef<LayoutDialog>,
    @Inject(MAT_DIALOG_DATA) public data: GraphicsDialogData,
    public afs: AngularFirestore
  ) {
    console.log("GraphicsDialog constructor" + data);

    this.COMPANYNAME = data.COMPANYNAME;
    this.layout = data.layout;
    this.grafiken = data.grafiken;
    this.layouttype = data.layouttype;

    this.graphicCategories = this.afs
      .collection("wlcompanies/" + this.COMPANYNAME + "/graphics")
      .snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data2 = a.payload.doc.data() as Layout;
          const id2 = a.payload.doc.id;
          data2.id2 = id2;
          const id = a.payload.doc.id;
          return { id2, id, ...data2 };
        });
      });
    this.graphicCategories.subscribe(x => {
      console.log("this.graphicCategoriesArray", x);
      this.graphicCategoriesArray = x;
    });
  }

  public addGrafikElement(imgurl) {
    var img = new Image();
    img.src = imgurl;
    img.onload = event => {
      // (<any>this).width + ' ' + (<any>this).height );
      //    console.log('test ', (<any>event).path[0].width);
      console.log("addgrafikelement ", event);
      var newGrafikElement: any = {}; // new GrafikElement();
      newGrafikElement.x = 10;
      newGrafikElement.y = 100;
      newGrafikElement.scaleX = 1;
      newGrafikElement.scaleY = 1;

      newGrafikElement.width = (<any>event).target.width;
      newGrafikElement.height = (<any>event).target.height;
      if (newGrafikElement.width > this.layout.width) {
        newGrafikElement.width = this.layout.width * 0.8;
        newGrafikElement.height = ((<any>event).target.height / (<any>event).target.width) * newGrafikElement.width;
      }

      if (newGrafikElement.height > this.layout.height) {
        newGrafikElement.height = this.layout.height * 0.8;
        newGrafikElement.width = ((<any>event).target.width / (<any>event).target.height) * newGrafikElement.height;
      }

      newGrafikElement.rotation = 0;
      newGrafikElement.imgurl = imgurl;
      this.layout.grafikelemente.push(newGrafikElement);
      this.dialogRef.close();
    };
  }

  tabClick(tab) {
    if (tab && this.graphicCategoriesArray) {
      if (this.graphicCategoriesArray.length > 0) {
        //        this.graphicsid = this.graphicCategoriesArray[tab.index].id;
        this.afs
          .collection(
            "wlcompanies/" + this.COMPANYNAME + "/graphics/" + this.graphicCategoriesArray[tab.index].id + "/urls",
            ref => ref.orderBy("index")
          )
          .snapshotChanges()
          .map(actions => {
            return actions.map(a => {
              const data = a.payload.doc.data() as Layout;
              const id2 = a.payload.doc.id;
              data.id2 = id2;
              const id = a.payload.doc.id;
              return { id2, id, ...data };
            });
          })
          .subscribe(x => {
            this.graphics = x;
          });
      }
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface TextsDialogData {
  layout: Layout;
  selectedLabel: any;
  font: any;
}
@Component({
  // tslint:disable-next-line:component-selector
  selector: "texts-dialog",
  templateUrl: "dlgtexts.html"
})
// tslint:disable-next-line:component-class-suffix
export class TextsDialog {
  layout: Layout;
  selectedLabel: any;
  font: any;
  model: any;
  constructor(public dialogRef: MatDialogRef<LayoutDialog>, @Inject(MAT_DIALOG_DATA) public data: TextsDialogData) {
    console.log("layoutdialog constructor");
    this.model = {};
    this.layout = data.layout;
    this.selectedLabel = data.selectedLabel;
    this.font = data.font;
  }
  public fontChanged(font) {
    console.log("fontchagned ", font);
    // Font {family: "Roboto Slab", styles: Array(2), style: "regular", files: null, size: "14px"}
    this.selectedLabel.fontfamily = font.family;
    this.selectedLabel.style = font.style;
    //    this.layout.labels[0].size = font.size;
  }

  public addText(text) {
    var newLabel: any = {}; // new Label();
    newLabel.content = text;
    newLabel.x = 100;
    newLabel.y = 200;
    newLabel.fontfamily = this.font.family;
    newLabel.fontsize = 150;
    newLabel.color = "#FFFFFF";
    newLabel.strokecolor = "#000000";
    newLabel.strokewidth = 2;
    newLabel.scaleX = 1;
    newLabel.scaleY = 1;
    newLabel.rotation = 0;
    newLabel.width = this.measureText(text, newLabel.fontsize, null).width;
    newLabel.height = this.measureText(text, newLabel.fontsize, null).height;
    this.layout.labels.push(newLabel);
  }

  private measureText(pText, pFontSize, pStyle) {
    var lDiv = <any>document.createElement("div");

    document.body.appendChild(lDiv);

    if (pStyle != null) {
      lDiv.style = pStyle;
    }
    lDiv.style.fontSize = "" + pFontSize + "px";
    lDiv.style.position = "absolute";
    lDiv.style.left = -100;
    lDiv.style.top = -100;

    lDiv.innerHTML = pText;

    var lResult = {
      width: lDiv.clientWidth,
      height: lDiv.clientHeight
    };

    document.body.removeChild(lDiv);
    lDiv = null;

    return lResult;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
