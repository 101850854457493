import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cronjob',
  templateUrl: './cronjob.component.html',
  styleUrls: ['./cronjob.component.css']
})
export class CronjobComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
