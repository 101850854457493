import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "photonr"
})
export class PhotoNrPipe implements PipeTransform {
  transform(value: number, isStrip: any, photoCount: number): any {
    var i = value;
    if (isStrip) {
      if (value+1 <= photoCount / 2) {
        i = value;
      } else {
        i = value - photoCount / 2;
      }
    }
    return i; // .replace(".png", " (Andere).png");
  }
}
