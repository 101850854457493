import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thumbnail'
})
export class ThumbnailPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    return value.replace(".png", " (Andere).png");
  }

}
