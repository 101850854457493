import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatSortable } from "@angular/material";
import {
  Paypalwebhook,
  Invoice,
  BillingAgreementDataSource,
  PaypalwebhookDataSource
} from "../invoices/invoices.component";
import { map } from "rxjs/operators";
import { AngularFirestore } from "@angular/fire/firestore";
import { LoggerService } from "../../logger.service";
import { Http } from "@angular/http";
import { AngularFireAuth } from "@angular/fire/auth";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-paypal",
  templateUrl: "./paypal.component.html",
  styleUrls: ["./paypal.component.css"]
})
export class PaypalComponent implements OnInit {
  dataSource: MatTableDataSource<Paypalwebhook>;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;

  filter: string;

  displayedColumns = ["id", "baid", "create_time", "event_type", "resource", "calltime"];

  webhookscalled: any;

  constructor(
    public afs: AngularFirestore,
    ls: LoggerService,
    public dialog: MatDialog,
    public http: Http,
    public auth: AngularFireAuth,
    private activatedRoute: ActivatedRoute
  ) {
    this.dataSource = new PaypalwebhookDataSource();
    this.dataSource.data = [
      {
        id: "",
        create_time: null,
        event_type: "",
        resource: null
        //   resource.state:string;
        // resource.billing_agreement_id :string;
      }
    ];

    this.afs
      .collection("webhookscalled", ref => ref.limit(100))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            if ((<any>data).resource) {
              (<any>data).baid = (<any>data).resource.billing_agreement_id;
            }
            return { id, ...data };
          });
        })
      )
      .subscribe(x => {
        if (x) {
          this.webhookscalled = x;
          this.dataSource.data = <any>x;
          this.dataSource.filter = this.filter;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      });
  }

  ngOnInit() {
    this.sort.sort(<MatSortable>{
      id: "calltime",
      start: "desc"
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}
