import { enableProdMode, LOCALE_ID } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
   .catch(err => console.log(err));
 
 
// temp bugfix: https://github.com/angular/angular-cli/issues/6683
// https://next.angular.io/guide/i18n
// platformBrowserDynamic([{provide: LOCALE_ID, useValue: 'de-DE'}]).bootstrapModule(AppModule, {providers: [{provide: LOCALE_ID, useValue: 'de-DE'}]});

// service worker funzt noch nicht
// platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
//     if ('serviceWorker' in navigator && environment.production) {
//       navigator.serviceWorker.register('/ngsw-worker.js');
//     }
//   }).catch(err => console.log(err));