import { Component, OnInit } from "@angular/core";

import { ActivatedRoute, Params } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { Layout } from "../model/layout";
import * as firebase from "firebase";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

declare var Jimp;

@Component({
  selector: "app-adminassets",
  templateUrl: "./adminassets.component.html",
  styleUrls: ["./adminassets.component.css"]
})
export class AdminassetsComponent implements OnInit {
  COMPANYNAME: string;
  company: any;
  newcategoryname: string;

  newbackgroundcategoryname: string;

  graphicCategoriesArray: any;
  graphicCategories: any;

  graphicsarray: any;

  backgroundgraphicCategoriesArray: any;
  backgroundgraphicCategories: any;
  backgroundgraphics: any;

  constructor(public afAuth: AngularFireAuth, private activatedRoute: ActivatedRoute, public afs: AngularFirestore) {}

  ngOnInit() {
    this.activatedRoute.parent.params.subscribe(params => {
      this.COMPANYNAME = params["company"];
      console.log("thiscompanyname" + this.COMPANYNAME);
      // WL-Domains Hack
    if (!this.COMPANYNAME && (
        //  window.location.host.indexOf("localhost") > -1 ||
        window.location.host.indexOf("flash-u") > -1 ||
        window.location.host.indexOf("c172a") > -1)
      ) {
        this.COMPANYNAME = "L4f9n7g85s9elzhqfBLT";
      }

      // tslint:disable-next-line:no-unused-expression
      <any>this.afs
        .doc("wlcompanies/" + this.COMPANYNAME)
        .valueChanges()
        .subscribe(x => {
          if (x) {
            this.company = x;
            console.log("thiscomapny", this.company);
            this.company.id = this.COMPANYNAME;
            this.loadgraphics();
          }
        });
    });

    // this.activatedRoute.queryParams.subscribe((params: Params) => {
    //   if (params["flashbooth"]) {
    //     console.log("flashbooth ", params["flashbooth"]), (this.flashboothbrowser = true);
    //   }
    // });
  }

  private loadgraphics() {
    this.graphicCategories = this.afs
      .collection("wlcompanies/" + this.COMPANYNAME + "/graphics")
      .snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Layout;
          const id2 = a.payload.doc.id;
          data.id2 = id2;
          const id = a.payload.doc.id;
          return { id2, id, ...data };
        });
      });
    this.graphicCategories.subscribe(x => {
      console.log("this.graphicCategoriesArray", x);
      this.graphicCategoriesArray = x;
    });

    this.backgroundgraphicCategories = this.afs
      .collection("wlcompanies/" + this.COMPANYNAME + "/backgroundgraphics")
      .snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Layout;
          const id2 = a.payload.doc.id;
          data.id2 = id2;
          const id = a.payload.doc.id;
          return { id2, id, ...data };
        });
      });
    this.backgroundgraphicCategories.subscribe(x => {
      console.log("this.backgroundgraphicCategoriesArray", x);
      this.backgroundgraphicCategoriesArray = x;
    });
  }

  public addGraphicsCategory() {
    console.log("addgrphiacscategory " + this.COMPANYNAME + this.newcategoryname);
    this.afs.collection("wlcompanies/" + this.COMPANYNAME + "/graphics").add({ name: this.newcategoryname });
  }
  public removeGraphicCategory(c) {
    this.afs
      .collection("wlcompanies/" + this.COMPANYNAME + "/graphics")
      .doc(c.id)
      .delete();
  }
  graphicUploaded($event, c) {
    Jimp.read($event.url).then(lenna => {
      var w = lenna.bitmap.width; //  width of the image
      var h = lenna.bitmap.height; // height of the image
      var ratio = w / h;
      var ratiow = w / 300;
      var neww = 300;
      var newh = 300 / ratio;
      if (w < h) {
        newh = 300;
        neww = 300 * ratio;
      }
      if (w < 300 && h < 300) {
        neww = w;
        newh = h;
      }
      console.log("lenna ", lenna);
      lenna.resize(neww, newh).getBuffer(Jimp.AUTO, async (err, src) => {
        var storageRef = firebase
          .storage()
          .ref()
          .child(this.COMPANYNAME + "/graphics/");

        var uploadTask = await storageRef
          .child($event.name + "_tn.png")
          .put(src, { contentType: "image/png" })
          .then(async x => {
            console.log("thumb uploaded ", x);
            var tu = await x.ref.getDownloadURL();
            console.log("tu", tu);

            this.afs
              .collection("wlcompanies/" + this.COMPANYNAME + "/graphics/" + c.id + "/urls")
              .add({ url: $event.url, tnurl: tu, index: this.graphicsarray.length });
          });
      });
    });
  }
  public removeGraphic(g, c) {
    this.afs
      .collection("wlcompanies/" + this.COMPANYNAME + "/graphics/" + c.id + "/urls")
      .doc(g.id)
      .delete();
  }

  graphicsid: any;
  backgroundgraphicsid: any;
  tabClick(tab) {
    if (tab && this.graphicCategoriesArray) {
      if (this.graphicCategoriesArray.length > 0) {
        this.graphicsid = this.graphicCategoriesArray[tab.index].id;
        this.afs
          .collection(
            "wlcompanies/" + this.COMPANYNAME + "/graphics/" + this.graphicCategoriesArray[tab.index].id + "/urls",
            ref => ref.orderBy("index")
          )
          .snapshotChanges()
          .map(actions => {
            return actions.map(a => {
              const data = a.payload.doc.data() as Layout;
              const id2 = a.payload.doc.id;
              data.id2 = id2;
              const id = a.payload.doc.id;
              return { id2, id, ...data };
            });
          })
          .subscribe(x => {
            this.graphicsarray = x;
          });
      }
    }
  }

  graphicUploadFailed($event) {
    alert("Upload fehlgeschlagen! Bitte versuchen Sie es erneut!");
  }

  ///////
  public addbackgroundGraphicsCategory() {
    this.afs
      .collection("wlcompanies/" + this.COMPANYNAME + "/backgroundgraphics")
      .add({ name: this.newbackgroundcategoryname });
  }
  public removebackgroundGraphicCategory(c) {
    this.afs
      .collection("wlcompanies/" + this.COMPANYNAME + "/backgroundgraphics")
      .doc(c.id)
      .delete();
  }
  backgroundgraphicUploaded($event, c) {
    Jimp.read($event.url).then(lenna => {
      var w = lenna.bitmap.width; //  width of the image
      var h = lenna.bitmap.height; // height of the image
      var ratio = w / h;
      var ratiow = w / 300;
      var neww = 300;
      var newh = 300 / ratio;
      if (w < h) {
        newh = 300;
        neww = 300 * ratio;
      }
      if (w < 300 && h < 300) {
        neww = w;
        newh = h;
      }
      console.log("lenna ", lenna);
      lenna.resize(neww, newh).getBuffer(Jimp.AUTO, async (err, src) => {
        var storageRef = firebase
          .storage()
          .ref()
          .child(this.COMPANYNAME + "/backgroundgraphics/");

        var uploadTask = await storageRef
          .child($event.name + "_tn.png")
          .put(src, { contentType: "image/png" })
          .then(async x => {
            console.log("thumb uploaded ", x);
            var tu = await x.ref.getDownloadURL();
            console.log("tu", tu);

            this.afs
              .collection("wlcompanies/" + this.COMPANYNAME + "/backgroundgraphics/" + c.id + "/urls")
              .add({ url: $event.url, tnurl: tu, index: this.backgroundgraphics.length });
          });
      });
    });
  }
  public removebackgroundGraphic(g, c) {
    this.afs
      .collection("wlcompanies/" + this.COMPANYNAME + "/backgroundgraphics/" + c.id + "/urls")
      .doc(g.id)
      .delete();
  }
  backgroundtabClick(tab) {
    if (tab) {
      if (this.backgroundgraphicCategoriesArray.length > 0) {
        this.backgroundgraphicsid = this.backgroundgraphicCategoriesArray[tab.index].id;

        this.afs
          .collection(
            "wlcompanies/" +
              this.COMPANYNAME +
              "/backgroundgraphics/" +
              this.backgroundgraphicCategoriesArray[tab.index].id +
            "/urls", ref => ref.orderBy("index")
          )
          .snapshotChanges()
          .map(actions => {
            return actions.map(a => {
              const data = a.payload.doc.data() as Layout;
              const id2 = a.payload.doc.id;
              data.id2 = id2;
              const id = a.payload.doc.id;
              return { id2, id, ...data };
            });
          })
          .subscribe(x => {
            this.backgroundgraphics = x;
          });
      }
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log("drop", event, this.graphicsarray);
    moveItemInArray(this.graphicsarray, event.previousIndex, event.currentIndex);

    // neue indizes speichern
    this.resetindices(
      this.graphicsid,
      "wlcompanies/" + this.COMPANYNAME + "/graphics/" + this.graphicsid + "/urls",
      this.graphicsarray
    );
  }

  dropbackground(event: CdkDragDrop<string[]>) {
    console.log("dropbackground", event, this.backgroundgraphicsid);
    moveItemInArray(this.backgroundgraphics, event.previousIndex, event.currentIndex);

    // neue indizes speichern
    this.resetindices(
      this.graphicsid,
      "wlcompanies/" + this.COMPANYNAME + "/backgroundgraphics/" + this.backgroundgraphicsid + "/urls",
      this.backgroundgraphics
    );
  }

  getindexbydocid(id, array) {
    for (var i = 0; i < array.length; i++) {
      if (id === array[i].id) {
        return i;
      }
    }
    return -1;
  }
  resetindices(graphicsID, collectionpath, array): Promise<void> {
    let refs = this.afs.collection(collectionpath);
    return refs.ref
      .get()
      .then(resp => {
        console.log(resp.docs);
        let batch = this.afs.firestore.batch();

        resp.docs.forEach(userDocRef => {
          batch.update(userDocRef.ref, { index: this.getindexbydocid(userDocRef.id, array) });
        });
        batch.commit().catch(err => console.error(err));
      })
      .catch(error => console.error(error));
  }
}
