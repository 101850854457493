import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { ActivatedRoute, Params } from "@angular/router";
import { ViewChild, ApplicationRef, AfterViewInit, ViewChildren, QueryList } from "@angular/core";
import { Http } from "@angular/http";
import { Layout } from "../model/layout";
import * as JSZip from "jszip";
import * as JSZipUtils from "jszip-utils";
import { BsDatepickerConfig, BsLocaleService } from "ngx-bootstrap/datepicker";

import { SvglayoutComponent } from "../svglayout/svglayout.component";
import { ImagePipe } from "../tobase64.pipe";
import { Utils } from "../utils/utils";
import { PopoverDirective } from "ngx-bootstrap/popover";
import * as firebase from "firebase";
import { DatePipe } from "@angular/common";

declare var saveAs;
declare var Jimp;

@Component({
  selector: "app-adminlayouts",
  templateUrl: "./adminlayouts.component.html",
  styleUrls: ["./adminlayouts.component.css"]
})
export class AdminlayoutsComponent implements OnInit, AfterViewInit {
  public COMPANYNAME = "flashu";
  public company: any;

  public selectedlayout: Layout;
  public userlayouts: any;
  public uploads: any;
  public count: number = 10;
  public SHOWCOUNT = 10;
  public loading = false;
  bsConfig: Partial<BsDatepickerConfig>;
  headColor: string;
  model: any;
  public counter: number;
  public sessionid: string;
  public flashboothbrowser: boolean;

  @ViewChild("mysvg") svglayout: SvglayoutComponent;

  @ViewChild("pop")
  pop: any;

  @ViewChild("popbreeze")
  popbreeze: any;
  @ViewChildren(PopoverDirective)
  popovers: QueryList<PopoverDirective>;

  // tslint:disable-next-line:member-ordering
  searchdate: any;
  // tslint:disable-next-line:member-ordering
  searchdateparsed: any;

  date1: any;
  date2: any;
  date3: any;

  constructor(
    public http: Http,
    public afAuth: AngularFireAuth,
    private activatedRoute: ActivatedRoute,
    public afs: AngularFirestore,
    public applicationRef: ApplicationRef,
    public localeService: BsLocaleService
  ) {



    // #svglayout [layout]="layout" [companyname]="COMPANYNAME" [sessionID]="sessionID" [counter]="counter" (layoutcreated)="layoutGenerated($event)"
    //   this.flashboothbrowser = false;
    //   this.count = this.SHOWCOUNT;
       this.model = {};
       this.model.dpi = "300 dpi";
       this.model.layoutwidth = 1844;
       this.model.layoutheight = 1240;
       this.model.mtype = "px";
    //   this.activatedRoute.params.subscribe((params: Params) => {
    //     console.log("params", params);
    //     this.COMPANYNAME = params["company"];
    //     // WL-Domains Hack
    //     if (
    //       //  window.location.host.indexOf("localhost") > -1 ||
    //       window.location.host.indexOf("flash-u") > -1 ||
    //       window.location.host.indexOf("c172a") > -1
    //     ) {
    //       this.COMPANYNAME = "L4f9n7g85s9elzhqfBLT";
    //     }
  }

  ngOnInit() {
    this.activatedRoute.parent.params.subscribe(params => {
      this.COMPANYNAME = params["company"];
      console.log("thiscompanyname" + this.COMPANYNAME);
      // WL-Domains Hack
      if (!this.COMPANYNAME && (
        //  window.location.host.indexOf("localhost") > -1 ||
        window.location.host.indexOf("flash-u") > -1 ||
        window.location.host.indexOf("c172a") > -1)
      ) {
        this.COMPANYNAME = "L4f9n7g85s9elzhqfBLT";
      }

      // tslint:disable-next-line:no-unused-expression
      <any>this.afs
        .doc("wlcompanies/" + this.COMPANYNAME)
        .valueChanges()
        .subscribe(x => {
          if (x) {
            this.company = x;
            console.log("thiscomapny", this.company);
            this.company.id = this.COMPANYNAME;
          }
        });

      this.loaddata(this.count); // layouts laden
    });

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params["flashbooth"]) {
        console.log("flashbooth ", params["flashbooth"]), (this.flashboothbrowser = true);
      }
    });

    // TODO: Achtung datepicker sprache!
    this.bsConfig = Object.assign({}, { locale: "de" }, { containerClass: "theme-red" });
    this.localeService.use("de");
  }

  ngAfterViewInit() {
    this.popovers.forEach((popover: PopoverDirective) => {
      popover.onShown.subscribe(() => {
        this.popovers.filter(p => p !== popover).forEach(p => p.hide());
      });
    });

    // wegen ngif
    // this.svglayouts.changes.subscribe((comps: QueryList<SvglayoutComponent>) => {
    //   console.log("svglayoutschange", comps);
    //   this.svglayout = (<any>comps)._results[0];
    //   console.log('setsvglayout', this.svglayout);
    // });
  }

  graphicUploadFailed($event) {
    alert("Upload fehlgeschlagen! Bitte versuchen Sie es erneut!");
  }

  loadmore() {
    this.count += this.SHOWCOUNT;
    this.loaddata(this.count);
  }

  loaddata(count) {
    // tslint:disable-next-line:radix
    this.count = parseInt(count);
    this.userlayouts = this.afs
      .collection<any>("companies/" + this.COMPANYNAME + "/userlayouts", ref =>
        ref.orderBy("datumErstellung", "desc").limit(this.count)
      )
      .snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Layout;
          const id2 = a.payload.doc.id;
          data.id2 = id2;
          const id = a.payload.doc.id;
          return { id2, id, ...data };
        });
      });

    // tslint:disable-next-line:no-unused-expression
    <any>this.afs
      .doc("wlcompanies/" + this.COMPANYNAME)
      .valueChanges()
      .subscribe(x => {
        if (x) {
          this.company = x;
          this.company.id = this.COMPANYNAME;
        }
      });
  }

  searchLayout(searchtext) {
    console.log("search " + searchtext.value.toLowerCase());
    if (searchtext.value === "") {
      this.loaddata(this.count);
      return;
    }
    var s = searchtext.value.toLowerCase();
    this.userlayouts = this.afs
      .collection<any>("companies/" + this.COMPANYNAME + "/userlayouts", ref =>
        ref.where("auftragsnummerName", ">=", s).where("auftragsnummerName", "<=", s + "zz")
      )
      .snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Layout;
          const id2 = a.payload.doc.id;
          data.id2 = id2;
          return { id2, ...data };
        });
      });
  }

  public saveName(j) {
    j.auftragsnummerName2 = j.auftragsnummerName.toLowerCase();
    this.afs
      .collection("companies/" + this.COMPANYNAME + "/userlayouts/")
      .doc(j.id2)
      .update(j);
  }

  searchLayoutByDate(searchtext) {
    if (searchtext === null) {
      this.loaddata(this.count);
      return;
    }
    var dateString = new Date(searchtext).toString();
    this.date1 = dateString;
    dateString = dateString
      .split(" ")
      .slice(0, 4)
      .join(" "); // Zeit entfernen
    this.date2 = dateString;

    this.searchdateparsed = Date.parse(dateString);

    this.date3 = this.searchdateparsed;
    console.log("searchLayoutByDate " + Date.parse(searchtext), " ", searchtext);
    this.userlayouts = this.afs
      .collection<any>("companies/" + this.COMPANYNAME + "/userlayouts", ref =>
        ref.where("datumVeranstaltung", "==", this.searchdateparsed)
      )
      .snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Layout;
          const id2 = a.payload.doc.id;
          data.id2 = id2;
          return { id2, ...data };
        });
      });
  }

  public px2mm(pixels: number, DPI: number): number {
    var mm = (pixels * 25.4) / DPI;
    return mm;
  }
  public mm2px(mm: number, DPI: number): number {
    var px = (mm * DPI) / 25.4;
    return px;
  }

  public makepublic(c) {
    c.id = null;
    if (this.company.kategorie1 === c.Kategorie) {
      c.Kategorie = "kategorie1";
    }
    if (this.company.kategorie2 === c.Kategorie) {
      c.Kategorie = "kategorie2";
    }
    if (this.company.kategorie3 === c.Kategorie) {
      c.Kategorie = "kategorie3";
    }
    if (this.company.kategorie4 === c.Kategorie) {
      c.Kategorie = "kategorie4";
    }
    if (this.company.kategorie5 === c.Kategorie) {
      c.Kategorie = "kategorie5";
    }
    this.afs.collection("companies/" + this.COMPANYNAME + "/layouts/").add(c);
  }

  deleteLayout(i) {
    console.log("companies/" + this.COMPANYNAME + "/userlayouts/" + i.id2, i);
    this.afs.doc("companies/" + this.COMPANYNAME + "/userlayouts/" + i.id2).delete();
  }

  uploadFailed($event) {
    alert("Upload fehlgeschlagen! Bitte versuchen Sie es erneut!");
  }

  public regenerateLayoutFiles(layout) {
    // alt, direkt in admin neu erstellen
    this.selectedlayout = layout;
    this.loading = true;
    this.applicationRef.tick();

    //   // ggf. Namen abändern
    // //  this.increaseCounterToAvailable();
    this.svglayout.layout = layout;
    console.log("regenerate Layout fies: ", layout);
    this.svglayout.generateLayoutFiles(1);
  }
  public regenerateLayoutFilesServer(layout) {
    // neu ueber task
    var task = { datetime: new Date().getTime(), task: "regeneratelayout", state: "pending", layout: layout };
    var tasks = this.afs
      .collection("tasks")
      .add(task)
      .then(y => console.log(y));
  }

  public layoutGenerated(event) {
    this.loading = false;
  }

  /**
   * Fetch the content and return the associated promise.
   * @param {String} url the url of the content to fetch.
   * @return {Promise} the promise containing the data.
   */
  private urlToPromise(url) {
    return new Promise(function(resolve, reject) {
      JSZipUtils.getBinaryContent(url, function(err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  public createZip(
    generatedAutoboothfile: string,
    generatedForeground: string,
    auftragsnummerName: string,
    layout: Layout
  ) {
    console.log("create zip");
    var zip = new JSZip();

    zip.file(
      generatedAutoboothfile
        .split("/")
        .pop()
        .split("?")[0]
        .split("?")[0]
        .split("%2F")[3],
      <any>this.urlToPromise(generatedAutoboothfile),
      { binary: true }
    );
    zip.file(
      generatedForeground
        .split("/")
        .pop()
        .split("?")[0]
        .split("?")[0]
        .split("%2F")[3],
      <any>this.urlToPromise(generatedForeground),
      { binary: true }
    );
    if (layout.showBranding) {
      zip.file("branding.png", <any>this.urlToPromise("assets/branding.png"), { binary: true });
      zip.file("branding-double.png", <any>this.urlToPromise("assets/branding-double.png"), { binary: true });
    }

    zip.generateAsync({ type: "blob" }).then(function(content) {
      var datestring =
        new Date(layout.datumVeranstaltung).getFullYear() +
        "-" +
        Utils.str_pad(new Date(layout.datumVeranstaltung).getMonth() + 1) +
        "-" +
        Utils.str_pad(new Date(layout.datumVeranstaltung).getDate());
      var zipname = "v1-" + datestring + "_" + layout.auftragsnummerName + ".zip";

      saveAs(content, zipname);
    });
  }

  public toRadians(angle) {
    return angle * (Math.PI / 180);
  }
  public rotateAroundPoint(cx, cy, x, y, angle) {
    var radians = (Math.PI / 180) * angle,
      cos = Math.cos(radians),
      sin = Math.sin(radians),
      nx = cos * (x - cx) + sin * (y - cy) + cx,
      ny = cos * (y - cy) - sin * (x - cx) + cy;
    return [nx, ny];
  }

  public rotChange(f) {
    //        console.log('rotchange ', f.rotation, ' ', this.toRadians(f.rotation), '  ', Math.cos(this.toRadians(-f.rotation)));
    // f.xalt = Math.round( f.x+ f.width / 2 * (1 - Math.cos(this.toRadians(-f.rotation))));
    // f.yalt = Math.round( f.y + f.width / 2 * Math.sin(this.toRadians(-f.rotation)));

    var rotated = [];
    rotated.push(this.rotateAroundPoint(f.x + f.width / 2, f.y + f.height / 2, f.x, f.y, f.rotation));
    rotated.push(this.rotateAroundPoint(f.x + f.width / 2, f.y + f.height / 2, f.x + f.width, f.y, f.rotation));
    rotated.push(
      this.rotateAroundPoint(f.x + f.width / 2, f.y + f.height / 2, f.x + f.width, f.y + f.height, f.rotation)
    );
    rotated.push(this.rotateAroundPoint(f.x + f.width / 2, f.y + f.height / 2, f.x, f.y + f.height, f.rotation));
    var smallestX = rotated[0][0];
    var smallestY = rotated[0][1];
    rotated.forEach(element => {
      if (element[0] < smallestX) {
        smallestX = element[0];
      }
      if (element[1] < smallestY) {
        smallestY = element[1];
      }
    });

    console.log("smallesst " + smallestX, " ", smallestY);
    f.xalt = Math.round(smallestX);
    f.yalt = Math.round(smallestY);
    f.widthalt = f.width;
    f.heightalt = f.height;
  }

  private loadXHR(url) {
    return new Promise(function(resolve, reject) {
      try {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.onerror = function() {
          reject("Network error.");
        };
        xhr.onload = function() {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject("Loading error:" + xhr.statusText);
          }
        };
        xhr.send();
      } catch (err) {
        reject(err.message);
      }
    });
  }
  private longToByteArray(/*long*/ long) {
    // we want to represent the input as a 8-bytes array
    var byteArray = [0, 0, 0, 0];

    for (var index = 0; index < byteArray.length; index++) {
      // tslint:disable-next-line:no-bitwise
      var byte = long & 0xff;
      byteArray[index] = byte;
      long = (long - byte) / 256;
    }

    return byteArray;
  }

  public generateBreezeFile(sessionIdwithCounter, layout: Layout, model, overlayurl: string): string {
    console.log("admin generateBreezefile");
    //         XML text
    // delimiter: 0xE5 0x5E
    // size of thumbnail preview JPEG: 4 bytes
    // size of optional header.jpg: 4 bytes
    // size of optional footer.jpg: 4 bytes
    // size of optional background.jpg: 4 bytes
    // size of optional overlay.png: 4 bytes
    // binary data for thumbnail JPEG (no more than 200x200 pixels)
    // binary data for optional header.jpg
    // binary data for optional footer.jpg
    // binary data for optional background.jpg
    // binary data for optional overlay.png

    // tslint:disable-next-line:no-shadowed-variable
    console.log(layout.photoframes);
    for (let f of layout.photoframes) {
      console.log('rotchagne');
      this.rotChange(f);
    }
    layout.width = model.layoutwidth;
    layout.height = model.layoutheight;

    if (model.mtype === "mm") {
      layout.width = this.mm2px(model.layoutwidth, model.dpi);
      layout.height = this.mm2px(model.layoutheight, model.dpi);
    }
    var f = 1; // 600dpi
    var s =
      '<?xml version="1.0" encoding="UTF-8"?>' +
      '<breeze_systems_photobooth build_date="Feb 19 2018" version="3.9.1" application="DSLR Remote Pro for Windows">\r\n' +
      "<photobooth_print_layout>\r\n" +
      '  <printer_settings aspect_ratio_width="3" aspect_ratio_height="2" width="' +
      Math.round(layout.width * f) +
      '" height="' +
      Math.round(layout.height * f) +
      '" x_dpi="' +
      model.dpi +
      '" y_dpi="' +
      model.dpi +
      '" left_margin="0" top_margin="0" right_margin="0" bottom_margin="0" background_color="16777215">\r\n</printer_settings>\r\n';
    // fotos

    var i = 1;
    layout.photoframes.forEach(frame => {
      s +=
        "<PhotoboothCustomLayout" +
        i +
        ' image="' +
        i +
        '" left="' +
        Math.round(frame.xalt * f) +
        '" top="' +
        Math.round(frame.yalt * f) +
        '" width="' +
        Math.round(frame.width * f) +
        '" height="' +
        Math.round(frame.height * f) +
        '" rotation="' +
        frame.rotation * 10 +
        '"></PhotoboothCustomLayout' +
        i +
        ">\r\n";
      i = i + 1;
    });

    s += " </photobooth_print_layout>" + "</breeze_systems_photobooth>\n";
    console.log('xml generated, overlay:  '+overlayurl);

    //    this.loadXHR("https://firebasestorage.googleapis.com/v0/b/layoutgenerator-c172a.appspot.com/o/flashu%2Flayoutfiles%2Fv1_2018_04_30_zrdfgdf_1%2Fv1_2018_04_30_zrdfgdf_1_foreground.png?alt=media&token=26fd0262-abfb-426e-a3c8-af92fcf21538").then((imgblob: Blob) => {

    // TODO: ersetzen
    this.loadXHR(overlayurl).then((imgblob: Blob) => {
      //    https://firebasestorage.googleapis.com/v0/b/layoutgenerator-c172a.appspot.com/o/flashu%2Flayoutfiles%2Fv1_2018_04_30_kjhkhjjk_1%2Fv1_2018_04_30_kjhkhjjk_1_foreground.png?alt=media&token=17cdd00e-94a2-44bc-8417-7c24c3b2eef8
      // this.loadXHR("http://localhost:4200/assets/Logos/logo_blume4.png").then((imgblob: Blob) => {
      // here the image is a blob
      var bs = imgblob.size;
      var ba = this.longToByteArray(bs);
      console.log("imgblobsize ", bs, "ba ", ba);
      var bytes = [229, 94, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].concat(ba);
      var ab = new ArrayBuffer(bytes.length); // bytes is the array with the integer
      var ia = new Uint8Array(ab);
      // tslint:disable-next-line:no-shadowed-variable
      for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes[i];
      }

      var myblob = new Blob([s], {
        type: "text/plain"
      });
      var blob = new Blob([ia], { type: "application/octet-stream" });

      var b = new Blob([myblob, blob, imgblob]);
      saveAs(b, layout.auftragsnummerName + ".pblt");
    });

    // var storageRef = firebase.storage().ref().child(this.COMPANY + '/layoutfiles/' + sessionIdwithCounter);
    // var uploadTaskanr = storageRef.child(sessionIdwithCounter + "_customerimage.png").put(blob).then(async (urlanr) => {
    return s;
  }

  public overlayCreated(event) {
    console.log("overlaycreated ", event);
    var sessionIdwithCounter = this.sessionid + this.counter;
    this.generateBreezeFile(sessionIdwithCounter, this.selectedlayout, this.model, event.overlay); // "https://firebasestorage.googleapis.com/v0/b/layoutgenerator-c172a.appspot.com/o/flashu%2Flayoutfiles%2Fv1_2018_04_30_kjhkhjjk_1%2Fv1_2018_04_30_kjhkhjjk_1_foreground.png?alt=media&token=17cdd00e-94a2-44bc-8417-7c24c3b2eef8");
  }

  public async breezeLayout(layout) {
    console.log("svglayout", this.svglayout);
    this.selectedlayout = layout;
    console.log("selctedlayout ", this.selectedlayout);

       var dp = new DatePipe("de");
    var d = "v1_" + dp.transform(layout.datumVeranstaltung, "yyyy_MM_dd");
    this.sessionid = d + "_" + layout.auftragsnummerName;

    var a = this.selectedlayout.generatedForegroundFilename.split("_"); // bsp: v1_2018_05_15_gdfgdf_1_foreground.png
    this.sessionid = a[0] + "_" + a[1] + "_" + a[2] + "_" + a[3] + "_" + a[4];
    this.counter = <any>a[5];
    this.svglayout.sessionID = this.sessionid;
    this.svglayout.counter = "" + this.counter;
    // TODO: quer/hochformat beachten, rotationen(!)
    this.svglayout.layout = this.selectedlayout;
    this.svglayout.layout.width = this.model.layoutwidth;
    this.svglayout.layout.height = this.model.layoutheight;
    this.svglayout.reformatLayout(this.model.layoutwidth / 1844, this.model.layoutheight / 1240);
 
    await this.svglayout.generateBreezeOverlay(this.model.layoutwidth, this.model.layoutheight, this.counter);
  }
}
