import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";
import { fadeAnimation } from "../../animations";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.css"],
  animations: [fadeAnimation]
})
export class AdminComponent implements OnInit {
  COMPANYNAME: string;
  company: any;

  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public afs: AngularFirestore
  ) {
    this.activatedRoute.params.subscribe((params: Params) => {
      console.log("params", params);

      this.COMPANYNAME = params["company"];
      // WL-Domains Hack
      if (
        !this.COMPANYNAME &&
        //  window.location.host.indexOf("localhost") > -1 ||
        (window.location.host.indexOf("flash-u") > -1 || window.location.host.indexOf("c172a") > -1)
      ) {
        this.COMPANYNAME = "L4f9n7g85s9elzhqfBLT";
      }

      // tslint:disable-next-line:no-unused-expression
      <any>this.afs
        .doc("wlcompanies/" + this.COMPANYNAME)
        .valueChanges()
        .subscribe(x => {
          if (x) {
            this.company = x;
            this.company.id = this.COMPANYNAME;
          }
        });
    });

    // this.activatedRoute.queryParams.subscribe((params: Params) => {
    //   if (params["flashbooth"]) {
    //     console.log("flashbooth ", params["flashbooth"]), (this.flashboothbrowser = true);
    //   }
    // });
  }

  ngOnInit() {
    this.afAuth.authState.subscribe(user => {
      console.log(user);
      if (user) {
        this.router.navigate(["../admin/layouts"], { relativeTo: this.activatedRoute });
      }
    });
  }

  logout() {
    this.afAuth.auth.signOut().then(x => {
      this.router.navigate(["../admin"], { relativeTo: this.activatedRoute });
    });
  }
}
