import { Component, OnInit } from "@angular/core";
import { Http } from "@angular/http";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";

declare var saveAs;

@Component({
  selector: "app-wlusers",
  templateUrl: "./wlusers.component.html",
  styleUrls: ["./wlusers.component.css"]
})
export class WlusersComponent implements OnInit {
  public companies: any;
  public company: any;
  public uploads: any;
  public count: number;
  public SHOWCOUNT = 10;
  headColor: string;

  constructor(public http: Http, public afAuth: AngularFireAuth, public afs: AngularFirestore) {
    this.count = this.SHOWCOUNT;
    this.loaddata(this.count);
    this.company = {};
  }
  loadmore() {
    this.count += this.SHOWCOUNT;
    this.loaddata(this.count);
  }

  loaddata(count) {
    // tslint:disable-next-line:radix
    this.count = parseInt(count);
    this.companies = this.afs
      .collection<any>("wlcompanies/", ref => ref.orderBy("name", "desc").limit(this.count))
      .snapshotChanges()
      .map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data(); // as Layout;
          const id = a.payload.doc.id;

          return { id, ...data };
        });
      });
  }

  ngOnInit() {}

  deleteCompany(i) {
    var path = "wlcompanies/" + i.id2;
    var path2 = "companies/" + i.nameurl;
    this.afs.doc(path).delete();
    this.afs.doc(path2).delete();
  }

  public generatePassword() {
    var length = 8,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!§$%&/()=.,:;+*",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }
  /////////////////////

  public editCompany(frm, openDialog) {
    event.preventDefault();
    if (frm.valid) {
      if (this.company.id) {
        this.afs.doc("/wlcompanies/" + this.company.id).update(this.company);
      } else {
        // neuen Kunden anlegen
        this.afAuth.auth
          .createUserWithEmailAndPassword(this.company.email, this.company.pass)
          .then(user => {
            var idBefore = this.afs.createId();
            console.log(idBefore);

            this.company.uid = user.user.uid;
            this.afs
              .doc("/wlcompanies/TymgtU0HfOBRzjWYQH4s/")
              .valueChanges()
              .subscribe(x => {
                (<any>x).id = idBefore;
                (<any>x).name = this.company.name;
                (<any>x).notiz = this.company.notiz;
                (<any>x).pass = this.company.pass;
                (<any>x).email = this.company.email;
                (<any>x).layoutanzahl = this.company.layoutanzahl;
                (<any>x).guthaben = this.company.guthaben;

                this.afs.doc("/wlcompanies/" + idBefore).set(x);
                //                        this.afs.collection('/wlcompanies/').add(x);
              });
          })
          .catch(function(error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            alert(errorCode + " - " + errorMessage);
          });
      }

      openDialog.hide();
    }
  }
}
