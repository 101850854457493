import { Pipe, PipeTransform } from "@angular/core";
import { Http, RequestOptions, Headers, ResponseContentType } from "@angular/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/switchMap";

declare var base64;
@Pipe({ name: "toBase64" })
export class ImagePipe implements PipeTransform {
  constructor(private http: Http) {}

  transform(url: string) {
    const headers = new Headers({
      "Content-Type": "image/*"
    }); /* tell that XHR is going to receive an image as response, so it can be then converted to blob */
    //  if (url) {
    // base64 nicht filtern
    //   if (url.startsWith("data:")) {
    return Observable.create(observer => {
      observer.next(url);
    });
    //   }
    // }
    // return this.http
    //   .get(url, new RequestOptions({ responseType: ResponseContentType.Blob })) // specify that response should be treated as blob data
    //   .map(response => response.blob()) // take the blob
    //   .switchMap(blob => {
    //     console.log("switchMap", blob);
    //     // return new observable which emits a base64 string when blob is converted to base64
    //     return Observable.create(observer => {
    //       const reader = new FileReader();
    //       reader.readAsDataURL(blob); // convert blob to base64
    //       reader.onloadend = function() {
    //         console.log("onloaded ", reader.result);
    //         observer.next(reader.result); // emit the base64 string result
    //       };
    //     });
    //   });
  }
}
